<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Edit permission</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="section">
                                <div class="section-title">General</div>
                                <div class="col-12 mt-4">
                                    <label for="name" class="form-field-title">
                                        Permission name <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="name" v-model="permission.name" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="description" class="form-field-title">Description</label>
                                    <textarea
                                        class="form-control"
                                        id="description"
                                        v-model="permission.description"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button
                                        :disabled="!isAdmin"
                                        type="button"
                                        class="btn btn-primary"
                                        id="upload-btn"
                                        @click="update"
                                    >
                                        Update
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MessageItem from '@/components/Messages/MessageItem.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import validation from '@/mixins/validation'
import user from '@/mixins/user'
import api from '@/api'

export default {
    name: 'PermissionEdit',
    components: { FooterPanel, MasterView, MessageItem },
    data() {
        return {
            permission: {
                id: null,
                description: null,
                name: null,
            },
            validateRules: {
                name: { message: 'Field name is required' },
            },
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
    },
    mixins: [validation, user],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        cancel() {
            this.$router.go(-1)
        },
        clear() {
            this.permission = {
                description: null,
                name: null,
            }
        },
        update() {
            api.updatePermission(this.permission).then((response) => {
                if (response.status === 200) {
                    this.clear()
                    this.addMessages({
                        title: 'Success',
                        type: 'success',
                        text: 'Permission updated successfully',
                        timer: 3,
                    })
                    setTimeout(() => {
                        this.$router.push({ name: 'PermissionList' })
                    }, 3000)
                } else {
                    if (response.response.data.errors) {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: response.response.data.errors[0],
                            timer: 3,
                        })
                    } else {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: 'Permission not updated',
                            timer: 3,
                        })
                    }
                }
            })
        },
        load() {
            api.getPermission(this.$route.params.id).then((response) => {
                this.permission = response.data.data.permission
            })
        },
    },
    created() {
        this.load()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>
