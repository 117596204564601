<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Partner landing</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-12 mt-4">
                                <label for="title" class="form-field-title">
                                    Title landing <span class="required">*</span>
                                </label>
                                <input type="text" class="form-control" id="title" v-model="landing.title" />
                            </div>

                            <div class="col-12 mt-4">
                                <label v-if="!isLoading" for="file-input" class="form-field-title">
                                    Select zip archive with new lending page <span class="required">*</span>
                                </label>
                                <label v-else for="file-input" class="form-field-title">Uploading, please wait</label>
                                <div class="d-flex p-0" id="file-input">
                                    <div class="col-md-2 p-0 d-inline-block">
                                        <input
                                            type="file"
                                            class="input_file"
                                            ref="archive"
                                            accept=".zip"
                                            @change="handleArchiveFile"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-outline-primary"
                                            :disabled="isLoading"
                                            @click="$refs.archive.click()"
                                        >
                                            Archive
                                        </button>
                                    </div>
                                    <div class="col-md-2 pt-2">{{ getFilename }}</div>
                                </div>
                            </div>

                            <div class="col-12 mt-4">
                                <label for="description" class="form-field-title">Description</label>
                                <textarea
                                    id="description"
                                    class="form-control"
                                    rows="3"
                                    v-model="landing.description"
                                ></textarea>
                            </div>

                            <hr />

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="save">
                                        Create
                                    </button>
                                    <VueSpinner v-if="isLoading" size="20" color="#5A738E" class="spinner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import api from '@/api'
import validation from '@/mixins/validation'

export default {
    name: 'PartnerLandingCreate',
    components: { MessageItem, MasterView, FooterPanel },
    data() {
        return {
            landing: {
                archive: null,
                description: null,
                title: null,
            },
            validateRules: {
                archive: { message: 'Field archive is required' },
                title: { message: 'Field title must be at least 3 characters long' },
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
        getFilename() {
            return this.landing.archive ? this.landing.archive.name : ''
        },
    },
    mixins: [validation],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        handleArchiveFile(event) {
            this.landing.archive = event.target.files[0]
        },
        save() {
            const validationResult = this.validateForm(this.landing, this.validateRules)
            if (validationResult) {
                this.isLoading = true
                let land = new FormData()

                land.append('title', this.landing.title)
                land.append('archive', this.landing.archive)

                if (this.landing.description) {
                    land.append('description', this.landing.description)
                } else {
                    land.append('description', '')
                }

                api.landingPartnerStore(land).then((response) => {
                    if (response.status === 201) {
                        this.isLoading = false
                        this.addMessages({
                            title: 'Success',
                            type: 'success',
                            text: 'Landing was created',
                            timer: 3,
                        })
                    } else {
                        response.response.data.errors.forEach((message) => {
                            this.addMessages({
                                title: 'Error',
                                type: 'error',
                                text: message,
                                timer: 5,
                            })
                        })
                        this.isLoading = false
                    }
                })
            }
        },
    },
}
</script>
