<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Create fast registration lending</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-12 mt-4">
                                <label for="title" class="form-field-title">
                                    Title landing <span class="required">*</span>
                                </label>
                                <input type="text" class="form-control" id="title" v-model="landing.title" />
                            </div>

                            <!-- country -->
                            <div class="col-12 mt-4">
                                <label for="country-select" class="form-field-title"
                                    >Select country <span class="required">*</span></label
                                >
                                <select
                                    class="form-control input-sm"
                                    id="country-select"
                                    v-model="landing.country_id"
                                    @change="changeCountry"
                                >
                                    <option
                                        style="font-size: 13px !important"
                                        v-for="country in filteredCountry"
                                        :key="country.id"
                                        :value="country.id"
                                    >
                                        {{ country.title }}
                                    </option>
                                </select>
                            </div>

                            <!-- login -->
                            <div class="col-12 mt-4 d-flex">
                                <div class="col-8 p-0">
                                    <label for="domain-link" class="form-field-title">Current domain</label>
                                    <input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        id="domain-link"
                                        v-model="landing.current_domain"
                                    />
                                </div>
                                <div class="col-4 p-0">
                                    <label for="login-link" class="form-field-title">Login link</label>
                                    <input type="text" class="form-control" id="login-link" v-model="landing.login" />
                                </div>
                            </div>

                            <!-- rules -->
                            <div class="col-12 mt-4 d-flex">
                                <div class="col-8 p-0">
                                    <label for="domain-link" class="form-field-title">Current domain</label>
                                    <input
                                        disabled
                                        type="text"
                                        class="form-control"
                                        id="domain-link"
                                        v-model="landing.current_domain"
                                    />
                                </div>
                                <div class="col-4 p-0">
                                    <label for="rules-link" class="form-field-title">Rules link</label>
                                    <input type="text" class="form-control" id="rules-link" v-model="landing.rules" />
                                </div>
                            </div>

                            <!-- -->
                            <div class="col-12 mt-4">
                                <label v-if="!isLoading" for="file-input" class="form-field-title"
                                    >Select zip archive with new lending page <span class="required">*</span></label
                                >
                                <label v-else for="file-input" class="form-field-title">Uploading, please wait</label>
                                <div class="d-flex p-0" id="file-input">
                                    <div class="col-md-2 p-0 d-inline-block">
                                        <input
                                            type="file"
                                            class="input_file"
                                            ref="archive"
                                            accept=".zip"
                                            @change="handleArchiveFile"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-outline-primary"
                                            :disabled="isLoading"
                                            @click="$refs.archive.click()"
                                        >
                                            Archive
                                        </button>
                                    </div>
                                    <div class="col-md-6 pt-2">{{ getFilename }}</div>
                                </div>
                            </div>

                            <!-- -->
                            <div class="col-12 mt-4">
                                <label for="type-select" class="form-field-title">Landing page type</label>
                                <select class="form-control input-sm" id="type-select" v-model="landing.type">
                                    <option style="font-size: 13px !important" value="sport">Sport</option>
                                    <option style="font-size: 13px !important" value="casino">Casino</option>
                                </select>
                            </div>

                            <div class="col-12 mt-4">
                                <label for="redirect-select" class="form-field-title">Redirect link</label>
                                <select
                                    class="form-control input-sm"
                                    id="redirect-select"
                                    v-model="landing.redirect_link"
                                >
                                    <option style="font-size: 13px !important" value="main">Main</option>
                                    <option style="font-size: 13px !important" value="deposit">Deposit</option>
                                </select>
                            </div>

                            <div class="col-12 mt-4">
                                <label for="description" class="form-field-title">Description</label>
                                <textarea
                                    id="description"
                                    class="form-control"
                                    rows="3"
                                    v-model="landing.description"
                                ></textarea>
                            </div>

                            <hr />
                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        :disabled="isLoading"
                                        id="upload-btn"
                                        @click="save"
                                    >
                                        Create
                                    </button>
                                    <VueSpinner v-if="isLoading" size="20" color="#5A738E" class="spinner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useCountriesStore } from '@/store/countries'
import { useDomainsStore } from '@/store/domains'
import { useMessagesStore } from '@/store/messages'
import api from '@/api'

import MasterView from '@/views/Admin/MasterView.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import { VueSpinner } from 'vue3-spinners'
import validation from '@/mixins/validation'

export default {
    name: 'FastRegistrationCreate',
    data() {
        return {
            landing: {
                archive: null,
                country_id: null,
                current_domain: null,
                description: null,
                title: null,
                type: 'sport',
                login: null,
                redirect_link: 'main',
                rules: null,
            },
            validateRules: {
                archive: { message: 'Field archive is required' },
                country_id: { message: 'Field country is required' },
                title: { message: 'Field title must be at least 3 characters long' },
            },
            isLoading: false,
        }
    },
    components: { MessageItem, FooterPanel, MasterView, VueSpinner },
    computed: {
        ...mapState(useCountriesStore, ['getCountries']),
        ...mapState(useDomainsStore, ['getDomains']),
        ...mapState(useMessagesStore, ['getMessages']),
        getFilename() {
            return this.landing.archive ? this.landing.archive.name : ''
        },
        filteredCountry() {
            if (this.getDomains.items) {
                const domainCountryIds = this.getDomains.items.map((item) => {
                    return item.country_id
                })
                domainCountryIds.sort()
                return this.getCountries
                    .filter((item) => {
                        if (domainCountryIds.includes(item.id)) {
                            return item
                        }
                    })
                    .sort((a, b) => {
                        const titleA = a.title.toLowerCase()
                        const titleB = b.title.toLowerCase()
                        if (titleA < titleB) return -1
                        if (titleA > titleB) return 1
                        return 0
                    })
            }
            return []
        },
    },
    mixins: [validation],
    methods: {
        ...mapActions(useCountriesStore, ['loadCountries']),
        ...mapActions(useDomainsStore, ['loadDomains']),
        ...mapActions(useMessagesStore, ['addMessages']),
        changeCountry() {
            const domains = this.getDomains
            domains.items.forEach((domain) => {
                if (domain.country_id === this.landing.country_id) {
                    this.landing.current_domain = domain.current_domain
                }
            })
        },
        handleArchiveFile(event) {
            this.landing.archive = event.target.files[0]
        },
        landingClear() {
            this.landing = {
                archive: null,
                country_id: null,
                description: null,
                title: null,
                type: 'sport',
            }
        },
        save() {
            if (this.validateForm(this.landing, this.validateRules)) {
                this.isLoading = true
                let land = new FormData()

                land.append('type', this.landing.type)

                if (this.landing.archive) {
                    land.append('archive', this.landing.archive)
                }

                if (this.landing.country_id) {
                    land.append('country_id', this.landing.country_id)
                }

                if (this.landing.description) {
                    land.append('description', this.landing.description)
                }

                if (this.landing.title) {
                    land.append('title', this.landing.title)
                }

                if (this.landing.login) {
                    land.append('login', this.landing.current_domain + this.landing.login)
                } else {
                    land.append('login', this.landing.current_domain)
                }

                if (this.landing.rules) {
                    land.append('rules', this.landing.current_domain + this.landing.rules)
                } else {
                    land.append('rules', this.landing.current_domain)
                }

                land.append('redirect_link', this.landing.redirect_link)
                api.landingStore(land).then((response) => {
                    if (response.status === 201) {
                        this.isLoading = false
                        this.landingClear()
                        this.addMessages({
                            title: 'Success',
                            type: 'success',
                            text: 'Landing was created',
                            timer: 3,
                        })
                    } else {
                        response.response.data.errors.forEach((message) => {
                            this.addMessages({
                                title: 'Error',
                                type: 'error',
                                text: message,
                                timer: 5,
                            })
                        })

                        this.isLoading = false
                    }
                })
            }
        },
    },
    created() {
        this.loadCountries()
        this.loadDomains({})
    },
}
</script>
