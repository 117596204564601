import { defineStore } from 'pinia'

export const useMessagesStore = defineStore('messages', {
    state: () => ({
        messages: [],
    }),
    getters: {
        getMessages: ({ messages }) => messages,
    },
    actions: {
        addMessages(messages) {
            this.messages = [...this.messages, messages]
        },
        clearMessages() {
            this.messages = []
        },
        removeMessage(index) {
            this.messages.splice(index, 1)
        },
    },
})
