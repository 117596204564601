// Authentication & Middleware
import auth from '@/router/middleware/auth'
import menu from '@/router/middleware/menu'

// Views
import UpView from '@/views/UpView.vue'
import Error404 from '@/views/Errors/Error404.vue'
import Error500 from '@/views/Errors/Error500.vue'
import LoginView from '@/views/Auth/LoginView.vue'

// Admin Clients
import ClientList from '@/views/Admin/Clients/ClientList.vue'

// Admin Landings
// Create
import FastRegistrationLanding from '@/views/Admin/Landings/Create/FastRegistrationCreate.vue'
import GeneralLandingLink from '@/views/Admin/Landings/Create/GeneralLinkCreate.vue'
import GeneralLandingScript from '@/views/Admin/Landings/Create/GeneralScriptCreate.vue'
// List
import FastRegistrationLandingList from '@/views/Admin/Landings/List/FastRegistrationLandingList.vue'
import GeneralLandingLinkList from '@/views/Admin/Landings/List/GeneralLandingLinkList.vue'
import GeneralLandingScriptList from '@/views/Admin/Landings/List/GeneralLandingScriptList.vue'
// Edit
import GeneralScriptEdit from '@/views/Admin/Landings/Edit/GeneralScriptEdit.vue'
import GeneralLinkEdit from '@/views/Admin/Landings/Edit/GeneralLinkEdit.vue'
import FastRegistrationEdit from '@/views/Admin/Landings/Edit/FastRegistrationEdit.vue'

// Admin Domains
// Create
import DomainCreate from '@/views/Admin/Domains/Create/DomainCreate.vue'
// List
import DomainList from '@/views/Admin/Domains/List/DomainList.vue'
// Edit
import DomainEdit from '@/views/Admin/Domains/Edit/DomainEdit.vue'

// User
import UserList from '@/views/Admin/Users/List/UserList.vue'
import UserCreate from '@/views/Admin/Users/Create/UserCreate.vue'
import UserEdit from '@/views/Admin/Users/Edit/UserEdit.vue'

// Router
import { createRouter, createWebHistory } from 'vue-router'
import UserPermissions from '@/views/Admin/Users/Permission/UserPermissions.vue'
import RoleList from '@/views/Admin/Roles/List/RoleList.vue'
import RoleEdit from '@/views/Admin/Roles/Edit/RoleEdit.vue'
import RoleCreate from '@/views/Admin/Roles/Create/RoleCreate.vue'
import PermissionCreate from '@/views/Admin/Permissions/Create/PermissionCreate.vue'
import PermissionList from '@/views/Admin/Permissions/List/PermissionList.vue'
import PermissionEdit from '@/views/Admin/Permissions/Edit/PermissionEdit.vue'
import PartnerLandingList from '@/views/Admin/Landings/List/PartnerLandingList.vue'
import PartnerLandingCreate from '@/views/Admin/Landings/Create/PartnerLandingCreate.vue'
import PartnerClientsList from '@/views/Admin/Partners/List/PartnerClientsList.vue'

const routes = [
    { path: '/', name: 'up', component: UpView },
    // Auth
    { path: '/login', name: 'Login', component: LoginView },

    { path: '/admin/client-list', name: 'ClientList', component: ClientList, meta: { middleware: [auth, menu] } },

    // Domains
    { path: '/admin/domain-list', name: 'DomainList', component: DomainList, meta: { middleware: [auth, menu] } },
    {
        path: '/admin/create/domain',
        name: 'DomainCreate',
        component: DomainCreate,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/edit/domain/:id',
        name: 'DomainEdit',
        component: DomainEdit,
        meta: { middleware: [auth, menu] },
    },

    // Landings
    // Create
    {
        path: '/admin/create/fast-registration-lend',
        name: 'FastRegistrationLanding',
        component: FastRegistrationLanding,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/create/general-lend-link',
        name: 'GeneralLandingLink',
        component: GeneralLandingLink,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/create/general-lend-script',
        name: 'GeneralLandingScript',
        component: GeneralLandingScript,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/create/partner-landing',
        name: 'PartnerLandingCreate',
        component: PartnerLandingCreate,
        meta: { middleware: [auth, menu] },
    },
    // List
    {
        path: '/admin/fast-registration-landing-list',
        name: 'FastRegistrationLandingList',
        component: FastRegistrationLandingList,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/general-lend-link-list',
        name: 'GeneralLandingLinkList',
        component: GeneralLandingLinkList,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/general-lend-script-list',
        name: 'GeneralLandingScriptList',
        component: GeneralLandingScriptList,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/partner-landing-list',
        name: 'PartnerLandingList',
        component: PartnerLandingList,
        meta: { middleware: [auth, menu] },
    },
    // Edit
    {
        path: '/admin/edit/fast-registration-lend/:id',
        name: 'FastRegistrationEdit',
        component: FastRegistrationEdit,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/edit/general-lend-link/:id',
        name: 'GeneralLinkEdit',
        component: GeneralLinkEdit,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/edit/general-lend-script/:id',
        name: 'GeneralScriptEdit',
        component: GeneralScriptEdit,
        meta: { middleware: [auth, menu] },
    },
    // Trash
    {
        path: '/admin/trash/fast-registration-landing-list',
        name: 'FastRegistrationLandingDeletedList',
        component: FastRegistrationLandingList,
        props: { status: false },
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/trash/general-lend-link-list',
        name: 'GeneralLandingLinkDeletedList',
        component: GeneralLandingLinkList,
        props: { status: false },
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/trash/general-lend-script-list',
        name: 'GeneralLandingScriptDeletedList',
        component: GeneralLandingScriptList,
        props: { status: false },
        meta: { middleware: [auth, menu] },
    },

    // Users
    {
        path: '/admin/users',
        name: 'UserList',
        component: UserList,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/create/user',
        name: 'UserCreate',
        component: UserCreate,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/edit/user/:id',
        name: 'UserEdit',
        component: UserEdit,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/user/permissions',
        name: 'UserPermissions',
        component: UserPermissions,
        meta: { middleware: [auth, menu] },
    },

    // Roles
    {
        path: '/admin/create/role',
        name: 'RoleCreate',
        component: RoleCreate,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/roles',
        name: 'RoleList',
        component: RoleList,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/role/:id',
        name: 'RoleEdit',
        component: RoleEdit,
        meta: { middleware: [auth, menu] },
    },

    // Permissions
    {
        path: '/admin/create/permission',
        name: 'PermissionCreate',
        component: PermissionCreate,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/permissions',
        name: 'PermissionList',
        component: PermissionList,
        meta: { middleware: [auth, menu] },
    },
    {
        path: '/admin/permission/:id',
        name: 'PermissionEdit',
        component: PermissionEdit,
        meta: { middleware: [auth, menu] },
    },

    // Partners
    {
        path: '/admin/partner/client-list',
        name: 'PartnerClientsList',
        component: PartnerClientsList,
        meta: { middleware: [auth, menu] },
    },

    // Errors
    { path: '/404', name: 'Error404', component: Error404, meta: { requireAuth: false } },
    { path: '/500', name: 'Error500', component: Error500, meta: { requireAuth: false } },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }

    to.meta.middleware.forEach((item) => {
        item({ to, from, next })
    })

    return next()
})

export default router
