<template>
    <div class="top_nav">
        <div class="nav_menu">
            <div class="nav toggle">
                <a id="menu_toggle"><i class="fa fa-bars"></i></a>
            </div>
            <nav class="nav navbar-nav">
                <ul class="navbar-right">
                    <li class="nav-item dropdown open" :class="{ show: isShowMenu }" style="padding-left: 15px">
                        <a
                            href="#"
                            class="user-profile dropdown-toggle"
                            aria-haspopup="true"
                            id="navbarDropdown"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            @click="isShowMenu = !isShowMenu"
                        >
                            <img src="/images/img.jpg" alt="" />{{ getUser.name }}
                        </a>
                        <div
                            class="dropdown-menu dropdown-usermenu pull-right"
                            :class="{ show: isShowMenu }"
                            aria-labelledby="navbarDropdown"
                        >
                            <a class="dropdown-item" href="#" @click="logout">
                                <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="pull-right mr-3" />
                                Log Out
                            </a>
                        </div>
                    </li>

                    <!-- Administration -->
                    <div class="admin-dropdown" ref="adminDropdown" v-if="hasShowAdministrationMenu">
                        <font-awesome-icon :icon="['fas', 'wrench']" />
                        <h3
                            class="dropdown-toggle"
                            id="navbarDropdown"
                            @click="isOpenAdministration = !isOpenAdministration"
                        >
                            Administration
                        </h3>
                        <div class="admin-dropdown__container" :class="{ openMenu: isOpenAdministration }">
                            <ul class="admin-dropdown__list" @click="isAdminUsersListOpen = !isAdminUsersListOpen">
                                <span class="admin-dropdown__title">Users</span>
                                <li class="admin-dropdown__item" :class="{ openList: isAdminUsersListOpen }">
                                    <div class="admin-dropdown__inner">
                                        <router-link
                                            v-if="hasUserListPermission"
                                            class="admin-dropdown__link"
                                            :to="{ name: 'UserList' }"
                                        >
                                            <span>User list</span>
                                        </router-link>

                                        <router-link
                                            v-if="hasUserCreatePermission"
                                            class="admin-dropdown__link"
                                            :to="{ name: 'UserCreate' }"
                                        >
                                            <span>User create</span>
                                        </router-link>
                                    </div>
                                </li>
                            </ul>

                            <ul class="admin-dropdown__list" @click="isAdminRolesListOpen = !isAdminRolesListOpen">
                                <span class="admin-dropdown__title">Roles</span>
                                <li class="admin-dropdown__item" :class="{ openList: isAdminRolesListOpen }">
                                    <div class="admin-dropdown__inner">
                                        <router-link
                                            v-if="hasRoleListPermission"
                                            class="admin-dropdown__link"
                                            :to="{ name: 'RoleList' }"
                                        >
                                            <span>Role list</span>
                                        </router-link>
                                        <router-link
                                            v-if="hasRoleCreatePermission"
                                            class="admin-dropdown__link"
                                            :to="{ name: 'RoleCreate' }"
                                        >
                                            <span>Role create</span>
                                        </router-link>
                                    </div>
                                </li>
                            </ul>

                            <ul
                                class="admin-dropdown__list"
                                @click="isAdminPermissionListOpen = !isAdminPermissionListOpen"
                            >
                                <span class="admin-dropdown__title">Permission</span>
                                <li class="admin-dropdown__item" :class="{ openList: isAdminPermissionListOpen }">
                                    <div class="admin-dropdown__inner">
                                        <router-link
                                            v-if="hasPermissionListPermission"
                                            class="admin-dropdown__link"
                                            :to="{ name: 'PermissionList' }"
                                        >
                                            <span>Permission list</span>
                                        </router-link>
                                        <router-link
                                            v-if="hasPermissionCreatePermission"
                                            class="admin-dropdown__link"
                                            :to="{ name: 'PermissionCreate' }"
                                        >
                                            <span>Permission create</span>
                                        </router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/store/user'
import api from '@/api'

export default {
    name: 'TopPanel',
    data() {
        return {
            isOpenAdministration: false,
            isShowMenu: false,
            administrationMenu: {},
            isAdminUsersListOpen: false,
            isAdminRolesListOpen: false,
            isAdminPermissionListOpen: false,
        }
    },
    computed: {
        ...mapState(useUserStore, ['getUser']),
        hasShowAdministrationMenu() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.state
            }
            return false
        },
        hasUserListPermission() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.menu.users.includes('list')
            }
            return false
        },
        hasUserCreatePermission() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.menu.users.includes('create')
            }
            return false
        },
        hasRoleListPermission() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.menu.roles.includes('list')
            }
            return false
        },
        hasRoleCreatePermission() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.menu.roles.includes('create')
            }
            return false
        },
        hasPermissionListPermission() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.menu.permissions.includes('list')
            }
            return false
        },
        hasPermissionCreatePermission() {
            if (this.administrationMenu.menu) {
                return this.administrationMenu.menu.permissions.includes('create')
            }
            return false
        },
    },
    methods: {
        ...mapActions(useUserStore, ['loadUser']),
        loadAdministrationMenu() {
            api.getAdministrationMenu().then((response) => {
                this.administrationMenu = response.data.data.administration
            })
        },
        logout() {
            this.$cookies.remove('auth_token')
            this.$router.push('/login')
        },
        handleClickOutsideAdminDropdown(event) {
            const dropdown = this.$refs.adminDropdown
            if (dropdown && !dropdown.contains(event.target)) {
                this.isOpenAdministration = false
                this.isAdminUsersListOpen = false
                this.isAdminRolesListOpen = false
                this.isAdminPermissionListOpen = false
            }
        },
    },
    created() {
        this.loadUser()
        this.loadAdministrationMenu()
        document.addEventListener('click', this.handleClickOutsideAdminDropdown)
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutsideAdminDropdown)
    },
}
</script>

<style scoped>
.menu-section-title {
    margin: 10px;
    font-size: 14px;
}

.submenu {
    padding: 10px;
}

.menu-item {
    cursor: pointer;
}

.menu-item:hover {
    background-color: #00aeef;
}
</style>
