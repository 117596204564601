<template>
    <div>
        <div class="container body">
            <div class="main_container">
                <sidebar-panel />
                <top-panel />

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import TopPanel from '@/components/Layouts/TopPanel.vue'
import SidebarPanel from '@/components/Layouts/SidebarPanel.vue'
import { mapActions } from 'pinia'
import { useUserStore } from '@/store/user'

export default {
    name: 'MasterView',
    components: { TopPanel, SidebarPanel },
    data() {
        return {
            drawer: null,
            theme: {
                defaultTheme: 'dark',
            },
        }
    },
    methods: {
        ...mapActions(useUserStore, ['loadUser']),
    },
    created() {
        this.loadUser
    },
}
</script>
