<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Registered clients</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th class="table-header-title" @click="toggleSort('id')">
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>
                                    <th class="table-header-title" @click="toggleSort('bonus_choice')">
                                        bonus_choice
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('bonus_choice', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('bonus_choice', 'asc')"
                                        />
                                    </th>
                                    <th class="table-header-title" @click="toggleSort('country')">
                                        country
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('country', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('country', 'asc')"
                                        />
                                    </th>
                                    <th class="table-header-title search-column" @click="toggleSort('phone')">
                                        phone
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('phone', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('phone', 'asc')"
                                        />
                                    </th>
                                    <th class="table-header-title" @click="toggleSort('currency')">
                                        currency
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('currency', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('currency', 'asc')"
                                        />
                                    </th>
                                    <th class="table-header-title search-column" @click="toggleSort('email')">
                                        email
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('email', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('email', 'asc')"
                                        />
                                    </th>
                                    <!-- created -->
                                    <th class="table-header-title" @click="toggleSort('created_at')">
                                        created
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(client, index) in getClients.items" :key="client.id">
                                    <th scope="row">{{ getAmountNumber(index) }}</th>
                                    <td>{{ client.id }}</td>
                                    <td>{{ client.bonus_choice }}</td>
                                    <td>{{ client.country }}</td>
                                    <td>{{ client.phone ?? '-' }}</td>
                                    <td>{{ client.currency }}</td>
                                    <td>{{ client.email ?? '-' }}</td>
                                    <td>{{ formatDate(client.created_at) }}</td>
                                    <td>
                                        <font-awesome-icon
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon"
                                            @click="removeItem(client.id)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import { mapActions, mapState } from 'pinia'
import { useClientsStore } from '@/store/clients'
import Pagination from '@/components/Layouts/PaginationPanel.vue'

export default {
    name: 'ClientList',
    components: { Pagination, MasterView, FooterPanel },
    data() {
        return {
            perPage: 10,
            activeMenuItem: [],
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            searchObject: {
                text: '',
                active: false,
            },
        }
    },
    computed: {
        ...mapState(useClientsStore, ['getClients', 'getPagination']),
        isSearchActive() {
            return this.searchObject.active
        },
    },
    methods: {
        ...mapActions(useClientsStore, ['loadClients', 'removeClient']),
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('clientSearch')
            this.loadClients({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        changePage(currentPage) {
            this.loadClients({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: currentPage,
            })
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            return date.toLocaleString()
        },
        isMenuItemActive(item) {
            return this.activeMenuItem.includes(item)
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        load() {
            const perPage = localStorage.getItem('clientPerPage')
            const orderBy = localStorage.getItem('clientOrderBy')
            const searchClientActive = JSON.parse(localStorage.getItem('clientSearch'))
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchClientActive && searchClientActive.active) {
                this.searchObject.active = searchClientActive.active
                this.searchObject.text = searchClientActive.text
            }
            this.loadClients({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        onChangePerPage() {
            localStorage.setItem('clientPerPage', this.perPage)
            this.loadClients({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        removeItem(id) {
            this.removeClient(id)
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('clientSearch', JSON.stringify(this.searchObject))
                this.loadClients({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
            }
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        toggleMenuItem(item) {
            if (this.activeMenuItem.includes(item)) {
                this.activeMenuItem = []
            } else {
                this.activeMenuItem = []
                this.activeMenuItem.push(item)
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('clientOrderBy', JSON.stringify(this.orderBy))
            this.loadClients({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
    },
    created() {
        this.load()
    },
}
</script>
