import { mapState } from 'pinia'
import { useUserStore } from '@/store/user'

export default {
    computed: {
        ...mapState(useUserStore, ['getUser']),
        async isAdmin() {
            await this.$nextTick()
            if (!this.getUser) return false

            const roles = this.getUser.roles
            const adminRoleSlugs = ['superadmin']

            return roles && Array.isArray(roles) && roles.some((role) => adminRoleSlugs.includes(role.slug))
        },
    },
}
