<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Partner landing list</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- id -->
                                    <th
                                        v-if="!isCurrentUserHasRoleUser"
                                        class="table-header-title"
                                        @click="toggleSort('id')"
                                    >
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>
                                    <!-- author -->
                                    <th class="table-header-title" @click="toggleSort('author')">
                                        author
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('author', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('author', 'asc')"
                                        />
                                    </th>
                                    <!-- image -->
                                    <th class="table-header-title">image</th>

                                    <!-- description -->
                                    <th class="table-header-title search-column" @click="toggleSort('description')">
                                        description
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('description', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('description', 'asc')"
                                        />
                                    </th>
                                    <!-- title -->
                                    <th class="table-header-title search-column" @click="toggleSort('title')">
                                        title
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('title', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('title', 'asc')"
                                        />
                                    </th>
                                    <!-- created -->
                                    <th class="table-header-title" @click="toggleSort('created_at')">
                                        created
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(landing, index) in getLandings.items" :key="landing.id">
                                    <th scope="row">{{ getAmountNumber(index) }}</th>

                                    <td v-if="!isCurrentUserHasRoleUser">{{ landing['id'] }}</td>
                                    <td>{{ landing.author }}</td>
                                    <td>
                                        <img class="thumbnail" :src="getImage(landing)" alt="" />
                                    </td>

                                    <td class="cursor-pointer" title="Click for show full description">
                                        <Popper :content="landing.description">
                                            {{ getDescription(landing) }}
                                        </Popper>
                                    </td>

                                    <td>{{ landing['title'] }}</td>

                                    <td>{{ formatDate(landing.created_at) }}</td>

                                    <td class="col-1">
                                        <font-awesome-icon
                                            v-if="status"
                                            :icon="['fas', 'globe']"
                                            class="action-icon"
                                            title="go to resource"
                                            @click="goTo(landing)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import Pagination from '@/components/Layouts/PaginationPanel.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import { mapActions, mapState } from 'pinia'
import { useLandingsStore } from '@/store/landings'
import Popper from 'vue3-popper'
import user from '@/mixins/user'

export default {
    name: 'PartnerLandingList',
    components: { Popper, MasterView, FooterPanel, Pagination },
    data() {
        return {
            perPage: 10,
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            searchObject: {
                text: '',
                active: false,
            },
            status: true,
        }
    },
    computed: {
        ...mapState(useLandingsStore, ['getLandings', 'getPagination']),
        isSearchActive() {
            return this.searchObject.active
        },
        isCurrentUserHasRoleUser() {
            return this.isUser
        },
    },
    mixins: [user],
    methods: {
        ...mapActions(useLandingsStore, ['loadPartnerLandingList']),
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('partnerLandingSearch')
            this.loadPartnerLandingList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
        changePage(currentPage) {
            localStorage.setItem('fastRegCurrentPage', currentPage)
            this.loadPartnerLandingList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: currentPage,
            })
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            if (this.isTrashListComponent) {
                return date.toLocaleDateString()
            } else {
                return date.toLocaleString()
            }
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        getDescription(landing) {
            if (landing.description) {
                return landing.description.slice(0, 30) + '...'
            }
            return '-'
        },
        getImage(landing) {
            if (landing.path_to_image) {
                return process.env.VUE_APP_BACKEND_URL + 'storage/' + landing.path_to_image
            }

            return '/images/no_image.png'
        },
        goTo(landing) {
            window.open(process.env.VUE_APP_LAND_RESOURCE + landing.title, '_blank')
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        load() {
            const perPage = localStorage.getItem('partnerLandingPerPage')
            const orderBy = localStorage.getItem('partnerLandingOrderBy')
            const searchLanding = JSON.parse(localStorage.getItem('partnerLandingSearch'))
            const currentPage = localStorage.getItem('partnerLandingCurrentPage')
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchLanding && searchLanding.active) {
                this.searchObject.active = searchLanding.active
                this.searchObject.text = searchLanding.text
            }
            if (currentPage) {
                this.currentPage = currentPage
            }
            this.loadPartnerLandingList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
        onChangePerPage() {
            localStorage.setItem('perPage', this.perPage)
            this.loadPartnerLandingList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('partnerLandingSearch', JSON.stringify(this.searchObject))
                this.loadPartnerLandingList({
                    orderBy: this.orderBy,
                    perPage: this.perPage,
                    search: this.searchObject.text,
                    status: this.status,
                    currentPage: this.currentPage,
                })
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('PartnerLandingOrderBy', JSON.stringify(this.orderBy))
            this.loadPartnerLandingList({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: this.currentPage,
            })
        },
    },
    created() {
        this.load()
    },
}
</script>
