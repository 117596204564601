<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Edit domain</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-11 mt-4">
                                <label class="form-field-title">Domain url <span class="required">*</span></label>
                                <div v-for="(url, index) in domain.domains" :key="index" class="d-flex mt-3">
                                    <input type="text" class="form-control" v-model="domain.domains[index]" />
                                    <font-awesome-icon
                                        :icon="['far', 'square-plus']"
                                        class="action-add"
                                        @click="addDomain"
                                    />
                                    <font-awesome-icon
                                        :icon="['far', 'trash-can']"
                                        class="action-add"
                                        @click="removeUrl(index)"
                                    />
                                </div>
                            </div>

                            <div class="col-12 mt-4">
                                <label class="mt-2" for="country-id">Country <span class="required">*</span></label>
                                <select
                                    id="country-id"
                                    class="form-control"
                                    v-model="domain.country_id"
                                    :class="{ formHasError: hasError('country_id') }"
                                >
                                    <option v-for="country in getCountries" :key="country.id" :value="country.id">
                                        {{ country.title }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 mt-4">
                                <label class="mt-2" for="country-id">Currency <span class="required">*</span></label>
                                <select id="country-id" class="form-control" v-model="this.domain.currency_id">
                                    <option v-for="currency in getCurrencies" :key="currency.id" :value="currency.id">
                                        {{ currency.code }} - {{ currency.title }}
                                    </option>
                                </select>
                            </div>

                            <hr />
                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="update">
                                        Update
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MessageItem from '@/components/Messages/MessageItem.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import { mapActions, mapState } from 'pinia'
import { useCountriesStore } from '@/store/countries'
import { useCurrenciesStore } from '@/store/currencies'
import { useMessagesStore } from '@/store/messages'
import { useDomainsStore } from '@/store/domains'
import api from '@/api'

export default {
    name: 'DomainEdit',
    components: { MasterView, FooterPanel, MessageItem },
    data() {
        return {
            domain: {},
            errors: {
                urls: { active: false, text: 'Field domain urls is required' },
                country_id: { active: false, text: 'Field country is required' },
                currency_id: { active: false, text: 'Field currency is required' },
            },
        }
    },
    computed: {
        ...mapState(useCountriesStore, ['getCountries']),
        ...mapState(useCurrenciesStore, ['getCurrencies']),
        ...mapState(useMessagesStore, ['getMessages']),
        ...mapState(useDomainsStore, ['domain']),
    },
    methods: {
        ...mapActions(useCountriesStore, ['loadCountries']),
        ...mapActions(useCurrenciesStore, ['loadCurrencies']),
        ...mapActions(useMessagesStore, ['addMessages']),
        ...mapActions(useDomainsStore, ['loadDomain', 'addDomain']),
        addDomain() {
            this.domain.domains.push('')
        },
        cancel() {
            this.$router.go(-1)
        },
        update() {
            if (this.validation()) {
                let data = {
                    country_id: this.domain.country_id,
                    currency_id: this.domain.currency_id,
                    domains: [],
                }
                this.domain.domains.forEach((item) => {
                    data.domains.push(item)
                })

                api.updateDomain(this.domain.id, data).then((response) => {
                    if (response.status === 204) {
                        this.addMessages({
                            title: 'Success',
                            type: 'success',
                            text: 'Domain was updated',
                            timer: 3,
                        })
                        setTimeout(() => {
                            this.$router.push({ name: 'DomainList' })
                        }, 3000)
                    } else {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: 'Domain not updated',
                            timer: 3,
                        })
                    }
                })
            }
        },
        handleKeydown(evt) {
            if (evt.key === 'Escape') {
                this.cancel()
            } else if (evt.key === 'Enter') {
                //
            }
        },
        hasError(field) {
            return this.errors[field].active
        },
        removeUrl(index) {
            this.domain.domains.splice(index, 1)
        },
        validation() {
            this.errors.country_id.active = !this.domain.country_id
            this.errors.currency_id.active = !this.domain.currency_id
            this.errors.urls.active = this.domain.domains.some((url) => !url.trim())

            const hasErrors = Object.values(this.errors).some((error) => error.active)

            if (hasErrors) {
                const errorMessage = Object.values(this.errors).find((error) => error.active).text
                this.addMessages({
                    title: 'Error',
                    type: 'error',
                    text: errorMessage,
                    timer: 3,
                })
                return false
            }

            return true
        },
    },
    created() {
        api.getDomainById(this.$route.params.id).then((response) => {
            this.domain = response.data.data.domain
        })
        this.loadCountries()
        this.loadCurrencies({})
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>

<style scoped></style>
