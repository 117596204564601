<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Create role</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="section">
                                <div class="section-title">General</div>
                                <div class="col-12 mt-4">
                                    <label for="name" class="form-field-title">
                                        Role name <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="name" v-model="role.name" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="description" class="form-field-title">Description</label>
                                    <textarea
                                        class="form-control"
                                        id="description"
                                        v-model="role.description"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="section">
                                <div class="section-title">
                                    Permissions <small>(!) changes will be saved immediately</small>
                                </div>
                                <div class="d-flex mt-4">
                                    <div class="col-6 columns-list">
                                        <div class="row pl-3">Selected</div>
                                        <draggable v-model="selectedPermissions" group="roles" item-key="id">
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>

                                    <div class="col-6 columns-list">
                                        <div class="row pl-3">Unselected</div>
                                        <draggable v-model="unselectedPermissions" group="roles" item-key="id">
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>
                                </div>
                            </div>

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button
                                        :disabled="!isAdmin"
                                        type="button"
                                        class="btn btn-primary"
                                        id="upload-btn"
                                        @click="create"
                                    >
                                        Create
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MasterView from '@/views/Admin/MasterView.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import validation from '@/mixins/validation'
import api from '@/api'
import user from '@/mixins/user'
import draggable from 'vuedraggable'

export default {
    name: 'RoleCreate',
    components: { draggable, FooterPanel, MessageItem, MasterView },
    data() {
        return {
            pluckPermissionsArray: [],
            selectedPermissions: [],
            unselectedPermissions: [],
            role: {
                description: null,
                name: null,
            },
            validateRules: {
                name: { message: 'Field name is required' },
            },
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
    },
    mixins: [validation, user],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        cancel() {
            this.$router.go(-1)
        },
        clear() {
            this.role = {
                description: null,
                name: null,
            }
        },
        create() {
            let validationResult = this.validateForm(this.role, this.validateRules)
            if (validationResult) {
                this.role.permissions = this.selectedPermissions.map((permission) => permission.id)
                api.storeRole(this.role).then((response) => {
                    if (response.status === 201) {
                        this.clear()
                        this.addMessages({
                            title: 'Success',
                            type: 'success',
                            text: 'Role create successfully',
                            timer: 3,
                        })
                        setTimeout(() => {
                            this.$router.push({ name: 'RoleList' })
                        }, 3000)
                    } else {
                        if (response.response.data.errors) {
                            this.addMessages({
                                title: 'Error',
                                type: 'error',
                                text: response.response.data.errors[0],
                                timer: 3,
                            })
                        } else {
                            this.addMessages({
                                title: 'Error',
                                type: 'error',
                                text: 'Role not created',
                                timer: 3,
                            })
                        }
                    }
                })
            }
        },
        load() {
            api.permissionsList({}).then((response) => {
                this.unselectedPermissions = response.data.data.permissions.items
            })
        },
    },
    created() {
        this.load()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>
