import { defineStore } from 'pinia'
import api from '@/api'

export const useClientsStore = defineStore('clients', {
    state: () => ({
        clients: [],
    }),
    getters: {
        getClients: ({ clients }) => clients,
        getPagination: ({ clients }) => {
            const { ...paginationData } = clients
            return paginationData
        },
    },
    actions: {
        async loadClients(params) {
            const response = await api.clientList(params)
            if (response.status === 200) {
                this.clients = response.data.data.clients
            }
        },
        async loadPartnerClientList(params) {
            const response = await api.loadPartnerClients(params)
            if (response.status === 200) {
                this.clients = response.data.data.clients
            }
        },
        async removeClient(id) {
            const response = await api.removeClient(id)
            if (response.status === 200) {
                const index = this.clients.items.findIndex((client) => client.id === id)
                if (index !== -1) {
                    this.clients.items.splice(index, 1)
                }
            }
        },
    },
})
