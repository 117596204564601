import { defineStore } from 'pinia'
import api from '@/api'

export const useDomainsStore = defineStore('domains', {
    state: () => ({
        domains: [],
    }),
    getters: {
        getDomains: ({ domains }) => domains,
        getPagination: ({ domains }) => {
            const { ...paginationData } = domains
            return paginationData
        },
    },
    actions: {
        async loadDomains(params) {
            const response = await api.domainList(params)
            if (response.status === 200) {
                this.domains = response.data.data.domains
            }
        },
        async removeDomain(id) {
            const response = await api.removeDomain(id)
            if (response.status === 204) {
                const index = this.domains.items.findIndex((domain) => domain.id === id)
                if (index !== -1) {
                    this.domains.items.splice(index, 1)
                }
            }
        },
    },
})
