import { defineStore } from 'pinia'
import api from '@/api'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: {},
        users: [],
    }),
    getters: {
        getUser: ({ user }) => {
            return user.length ? user[0] : user
        },
        getUsers: ({ users }) => users,
        getPagination: ({ users }) => {
            const { ...paginationData } = users
            return paginationData
        },
    },
    actions: {
        async loadUser() {
            const response = await api.getCurrentUser()
            if (response.status === 200) {
                this.user = response.data.data.user
            }
        },
        async loadUsers(params) {
            const response = await api.userList(params)
            if (response.status === 200) {
                this.users = response.data.data.users
            }
        },
    },
})
