import { defineStore } from 'pinia'
import api from '@/api'

export const usePermissionsStore = defineStore('permissions', {
    state: () => ({
        permissions: [],
    }),
    getters: {
        getPermissionsList: ({ permissions }) => permissions.items,
        getPagination: ({ permissions }) => {
            const { ...paginationData } = permissions
            return paginationData
        },
    },
    actions: {
        async loadPermissions(params) {
            const response = await api.permissionsList(params)
            if (response.status === 200) {
                this.permissions = response.data.data.permissions
            }
        },
    },
})
