<template>
    <div class="message" v-show="isShow">
        <div class="message-box" v-for="(message, index) in getMessages" :key="index">
            <div class="message-icon">
                <img v-if="message.type === 'error'" class="message-icon-img" src="/icons/error.png" alt="error" />
                <img v-else class="message-icon-img" src="/icons/check.png" alt="error" />
            </div>
            <div class="message-message">
                <div class="message-message-title">{{ message.title }}</div>
                <div class="message-message-text">{{ message.text }}</div>
            </div>
            <div class="message-button">
                <span class="fa fa-times message-button-icon" @click="closeMessage(index)"></span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'

export default {
    name: 'MessageItem',
    data() {
        return {
            messages: [],
            timers: {},
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
        isShow() {
            return this.getMessages.length > 0
        },
    },
    methods: {
        ...mapActions(useMessagesStore, ['clearMessages', 'removeMessage']),
        closeMessage(index) {
            this.removeMessage(index)
        },
    },
    mounted() {
        this.$watch(
            () => useMessagesStore().messages,
            (newValue) => {
                let timer = 5000
                if (newValue.length && newValue[0].timer) {
                    timer = newValue[0].timer * 1000
                }

                setTimeout(() => {
                    this.clearMessages()
                }, timer)
            }
        )
    },
}
</script>
