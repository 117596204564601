<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Update general landing (link)</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-12 mt-4">
                                <label for="title" class="form-field-title">Title landing</label>
                                <input type="text" class="form-control" id="title" v-model="getLanding.title" />
                            </div>

                            <!-- -->
                            <div class="col-12 mt-4">
                                <label for="file-input" class="form-field-title">
                                    Select zip archive with new lending page
                                </label>
                                <div class="d-flex p-0" id="file-input">
                                    <div class="col-md-2 p-0 d-inline-block">
                                        <input
                                            type="file"
                                            class="input_file"
                                            ref="archive"
                                            accept=".zip"
                                            @change="handleArchiveFile"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-outline-primary"
                                            @click="$refs.archive.click()"
                                        >
                                            Archive
                                        </button>
                                    </div>
                                    <div class="col-md-6 pt-2">{{ getFilename }}</div>
                                </div>
                            </div>

                            <div class="col-12 mt-4">
                                <label for="country-select" class="form-field-title"
                                    >Select country <span class="required">*</span></label
                                >
                                <select
                                    class="form-control input-sm"
                                    id="country-select"
                                    v-model="getLanding.country_id"
                                >
                                    <option
                                        style="font-size: 13px !important"
                                        v-for="country in getCountries"
                                        :key="country.id"
                                        :value="country.id"
                                    >
                                        {{ country.title }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 mt-4">
                                <label for="action-link-desktop" class="form-field-title">
                                    Action button link for desktop
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="action-link-desktop"
                                    v-model="getLanding.linkForDesktop"
                                />
                            </div>

                            <div class="col-12 mt-4">
                                <label for="action-link-mobile" class="form-field-title">
                                    Action button link for mobile
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="action-link-mobile"
                                    v-model="getLanding.linkForMobile"
                                />
                            </div>

                            <div class="col-12 mt-4">
                                <label for="description" class="form-field-title">Description</label>
                                <textarea
                                    id="description"
                                    class="form-control"
                                    rows="3"
                                    v-model="getLanding.description"
                                ></textarea>
                            </div>

                            <hr />

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="update">
                                        Update
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MasterView from '@/views/Admin/MasterView.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import api from '@/api'
import { mapActions, mapState } from 'pinia'
import { useCountriesStore } from '@/store/countries'
import { useMessagesStore } from '@/store/messages'
import MessageItem from '@/components/Messages/MessageItem.vue'
import { useLandingsStore } from '@/store/landings'
import { useDomainsStore } from '@/store/domains'

export default {
    name: 'GeneralLinkEdit',
    computed: {
        ...mapState(useCountriesStore, ['getCountries']),
        ...mapState(useDomainsStore, ['getDomains']),
        ...mapState(useLandingsStore, ['getLanding']),
        ...mapState(useMessagesStore, ['getMessages']),
        getFilename() {
            return this.landing.archive ? this.landing.archive.name : ''
        },
        filteredCountry() {
            if (this.getDomains.items) {
                const domainCountryIds = this.getDomains.items.map((item) => {
                    return item.country_id
                })
                domainCountryIds.sort()
                return this.getCountries
                    .filter((item) => {
                        if (domainCountryIds.includes(item.id)) {
                            return item
                        }
                    })
                    .sort((a, b) => {
                        const titleA = a.title.toLowerCase()
                        const titleB = b.title.toLowerCase()
                        if (titleA < titleB) return -1
                        if (titleA > titleB) return 1
                        return 0
                    })
            }
            return []
        },
    },
    components: { MessageItem, FooterPanel, MasterView },
    data() {
        return {
            landing: {
                archive: null,
                action: 'link',
                country_id: null,
                description: null,
                linkForDesktop: null,
                linkForMobile: null,
                title: null,
                type: 'general',
            },
        }
    },
    methods: {
        ...mapActions(useCountriesStore, ['loadCountries']),
        ...mapActions(useDomainsStore, ['loadDomains']),
        ...mapActions(useLandingsStore, ['loadLanding']),
        ...mapActions(useMessagesStore, ['addMessages']),
        cancel() {
            this.$router.go(-1)
        },
        handleArchiveFile(event) {
            this.landing.archive = event.target.files[0]
        },
        update() {
            api.landingUpdate(this.getLanding).then((response) => {
                if (this.landing.archive) {
                    let archive = new FormData()
                    archive.append('id', this.getLanding.id)
                    archive.append('archive', this.landing.archive)
                    api.landingArchiveUpdate(archive)
                }

                if (response.status === 200) {
                    this.addMessages({
                        title: 'Success',
                        type: 'success',
                        text: 'Landing was updated',
                        timer: 3,
                    })
                    setTimeout(() => {
                        this.$router.push({ name: 'GeneralLandingLinkList' })
                    }, 3000)
                } else {
                    this.addMessages({
                        title: 'Error',
                        type: 'error',
                        text: 'Landing not updated',
                        timer: 5,
                    })
                }
            })
        },
    },
    created() {
        this.loadCountries()
        this.loadDomains({})
        this.loadLanding(this.$route.params.id)
    },
}
</script>
