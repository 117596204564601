<template>
    <!-- footer content -->
    <footer>
        <div class="pull-right"></div>
        <div class="clearfix"></div>
    </footer>
    <!-- /footer content -->
</template>

<script>
export default {
    name: 'FooterPanel',
}
</script>
