import { defineStore } from 'pinia'
import api from '@/api'

export const useRolesStore = defineStore('roles', {
    state: () => ({
        roles: [],
    }),
    getters: {
        getRoleList: ({ roles }) => roles.items,
        getPagination: ({ roles }) => {
            const { ...paginationData } = roles
            return paginationData
        },
    },
    actions: {
        async loadRoles(params) {
            const response = await api.rolesList(params)
            if (response.status === 200) {
                this.roles = response.data.data.roles
            }
        },
    },
})
