import { defineStore } from 'pinia'
import api from '@/api'

export const useCountriesStore = defineStore('countries', {
    state: () => ({
        countries: [],
    }),
    getters: {
        getCountries: ({ countries }) => countries,
    },
    actions: {
        async loadCountries() {
            const response = await api.getAllCountries()
            if (response.status === 200) {
                this.countries = response.data.data.countries
            }
        },
    },
})
