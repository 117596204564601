<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Edit user</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="section">
                                <div class="section-title">General</div>
                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        User name <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="name" v-model="user.name" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        User email <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="email" v-model="user.email" />
                                </div>
                            </div>

                            <div class="section">
                                <div class="section-title">
                                    Roles <small>(!) changes will be saved immediately</small>
                                </div>
                                <div class="d-flex mt-4">
                                    <div class="col-6">
                                        <div class="row pl-3">Selected</div>
                                        <draggable
                                            v-model="selectedRoles"
                                            group="roles"
                                            item-key="id"
                                            @end="updateCurrentUserRoles"
                                        >
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>

                                    <div class="col-6">
                                        <div class="row pl-3">Unselected</div>
                                        <draggable
                                            v-model="unselectedRoles"
                                            group="roles"
                                            item-key="id"
                                            @end="updateCurrentUserRoles"
                                        >
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>
                                </div>
                            </div>

                            <div class="section">
                                <div class="section-title">Password</div>
                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        Password (only if you want to change)
                                    </label>
                                    <input type="password" class="form-control" id="password" v-model="user.password" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        Password confirm (only if you want to change)
                                    </label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="password_confirmation"
                                        v-model="user.password_confirmation"
                                    />
                                </div>
                            </div>

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="update">
                                        Update
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import api from '@/api'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import { useUserStore } from '@/store/user'
import draggable from 'vuedraggable'
import user from '@/mixins/user'

export default {
    name: 'UserUpdate',
    components: { MasterView, MessageItem, FooterPanel, draggable },
    data() {
        return {
            user: {},
            pluckRolesArray: [],
            selectedRoles: [],
            unselectedRoles: [],
            errors: {
                email: { active: false, text: 'Field email is required' },
                name: { active: false, text: 'Field name is required' },
                password: { active: false, text: 'Field password is required' },
                password_confirmation: { active: false, text: 'Field password_confirmation is required' },
            },
        }
    },
    computed: {
        ...mapState(useUserStore, ['getUser']),
    },
    mixins: [user],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        cancel() {
            this.$router.go(-1)
        },
        load() {
            // Auth user
            this.loadUser

            // User for edit
            api.loadUserById(this.$route.params.id).then((response) => {
                if (response.status === 200) {
                    this.user = response.data.data.user
                    this.selectedRoles = response.data.data.user.roles

                    if (this.isAdmin) {
                        this.user.password = null
                        this.user.password_confirmation = null
                    } else {
                        this.$router.go(-1)
                    }

                    this.pluckRolesArray = response.data.data.user.roles.map((role) => role.slug)

                    api.rolesList({}).then((response) => {
                        this.unselectedRoles = response.data.data.roles.items.filter((role) => {
                            const isNotAssigned = !this.pluckRolesArray.includes(role.slug)

                            const isSuperadminAndUserNotSuperadmin =
                                role.slug === 'superadmin' && this.getUser.roles[0].slug !== 'superadmin'

                            return isNotAssigned && !isSuperadminAndUserNotSuperadmin
                        })
                    })
                }
            })
        },
        update() {
            api.updateUser(this.user).then((response) => {
                if (response.status === 200) {
                    this.addMessages({
                        title: 'Success',
                        type: 'success',
                        text: 'User updated successfully',
                        timer: 3,
                    })
                } else {
                    this.addMessages({
                        title: 'Error',
                        type: 'error',
                        text: 'User not updated',
                        timer: 3,
                    })
                }
            })
        },
        updateCurrentUserRoles() {
            const selectedRoleIds = this.selectedRoles.map((role) => role.id)

            api.userRolesChange(this.$route.params.id, { roles: selectedRoleIds })
        },
    },
    created() {
        this.load()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>
