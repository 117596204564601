import { mapActions } from 'pinia'
import { useMessagesStore } from '@/store/messages'

export default {
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        validateForm(formData, validationRules) {
            let isValid = true

            for (const field in validationRules) {
                const fieldValue = formData[field]
                const fieldRules = validationRules[field]

                if (fieldRules) {
                    const isFieldValid = this.validateField(fieldValue, fieldRules)
                    if (!isFieldValid) {
                        isValid = false
                    }
                }
            }

            return isValid
        },
        validateField(fieldValue, fieldRules) {
            if (fieldValue === null || fieldValue === undefined) {
                this.addMessages({
                    title: 'Error',
                    type: 'error',
                    text: fieldRules['message'],
                    timer: 5,
                })
                return false
            }

            return true
        },
    },
}
