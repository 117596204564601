<template>
    <div id="modal" v-if="modal.show">
        <div class="modal modal_open" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ modal.title }}</h5>
                        <font-awesome-icon :icon="['fas', 'xmark']" class="modal-close-button" @click="cancel" />
                    </div>
                    <div class="modal-body">
                        <p>{{ modal.text }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="agree">Yes</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="cancel">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RemoveModal',
    methods: {
        agree() {
            this.$emit('agree', {
                id: this.modal.id,
            })
        },
        cancel() {
            this.$emit('cancel')
        },
        handleKeydown(evt) {
            if (this.modal.show) {
                if (evt.key === 'Escape') {
                    this.cancel()
                } else if (evt.key === 'Enter') {
                    this.agree()
                }
            }
        },
    },
    props: {
        modal: {
            type: Object,
        },
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>
