<template>
    <master-view>
        <remove-modal :modal="removeModal" @cancel="removeModal.reset" @agree="removeRole" />
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>List roles</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- id -->
                                    <th class="table-header-title" @click="toggleSort('id')">
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>
                                    <!-- name -->
                                    <th class="table-header-title" @click="toggleSort('name')">
                                        name
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('name', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('name', 'asc')"
                                        />
                                    </th>
                                    <!-- slug -->
                                    <th class="table-header-title" @click="toggleSort('slug')">
                                        slug
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('email', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('email', 'asc')"
                                        />
                                    </th>
                                    <!-- permissions -->
                                    <th class="table-header-title col-4">permissions</th>
                                    <!-- description -->
                                    <th class="table-header-title col-2">description</th>
                                    <!-- created_at -->
                                    <th class="table-header-title" @click="toggleSort('created_at')">
                                        created_at
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(role, index) in getRoles" :key="role.id">
                                    <th scope="row">{{ getAmountNumber(index) }}</th>
                                    <td>{{ role.id }}</td>
                                    <td>{{ role.name }}</td>
                                    <td>{{ role.slug }}</td>
                                    <td class="permissions-cell">
                                        <span
                                            v-for="(permission, order) in getPermissions(role)"
                                            :key="order"
                                            class="role-item permission-tag"
                                        >
                                            {{ permission }}
                                        </span>
                                    </td>
                                    <td>
                                        <Popper :content="role.description">
                                            {{ getDescription(role) }}
                                        </Popper>
                                    </td>
                                    <td>{{ formatDate(role.created_at) }}</td>
                                    <td>
                                        <router-link :to="{ name: 'RoleEdit', params: { id: role.id } }" v-if="isAdmin">
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon" />
                                        </router-link>
                                        <a href="#" v-else>
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon-disable" />
                                        </a>
                                        <font-awesome-icon
                                            v-if="isAdmin"
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon"
                                            @click="removeItem(role.id)"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon-disable"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import RemoveModal from '@/components/Modals/RemoveModal.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import Popper from 'vue3-popper'
import { mapActions, mapState } from 'pinia'
import api from '@/api'
import { useRolesStore } from '@/store/role'
import { useUserStore } from '@/store/user'
import { usePermissionsStore } from '@/store/permissions'
import Pagination from '@/components/Layouts/PaginationPanel.vue'
import user from '@/mixins/user'

export default {
    name: 'RoleList',
    components: { Popper, Pagination, FooterPanel, MasterView, RemoveModal },
    computed: {
        ...mapState(useRolesStore, ['getRoleList', 'getPagination']),
        ...mapState(usePermissionsStore, ['getPermissionsList']),
        ...mapState(useUserStore, ['getUser']),
        isSearchActive() {
            return this.searchObject.active
        },
        getRoles() {
            if (this.getRoleList) {
                return this.getRoleList
            }
            return []
        },
    },
    data() {
        return {
            perPage: 10,
            activeMenuItem: [],
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            removeModal: {
                show: false,
                title: null,
                text: null,
                id: null,
                reset: () => {
                    this.removeModal.title = null
                    this.removeModal.text = null
                    this.removeModal.show = false
                },
            },
            searchObject: {
                text: '',
                active: false,
            },
            type: '',
            currentPage: null,
        }
    },
    mixins: [user],
    methods: {
        ...mapActions(useRolesStore, ['loadRoles']),
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('roleSearch')
            this.loadRoles({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        changePage(currentPage) {
            localStorage.setItem('roleCurrentPage', currentPage)
            this.loadRoles({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                status: this.status,
                currentPage: currentPage,
            })
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        getDescription(role) {
            if (role.description) {
                return role.description.slice(0, 30) + '...'
            }
            return '-'
        },
        getPermissions(role) {
            if (role && role.permissions) {
                return role.permissions.map((permission) => permission.slug)
            }
            return []
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            return date.toLocaleString()
        },
        load() {
            const perPage = localStorage.getItem('rolePerPage')
            const orderBy = localStorage.getItem('roleOrderBy')
            const searchLanding = JSON.parse(localStorage.getItem('roleSearch'))
            const currentPage = localStorage.getItem('roleCurrentPage')
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchLanding && searchLanding.active) {
                this.searchObject.active = searchLanding.active
                this.searchObject.text = searchLanding.text
            }
            if (currentPage) {
                this.currentPage = currentPage
            }
            this.loadRoles({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        onChangePerPage() {
            localStorage.setItem('rolePerPage', this.perPage)
            this.loadRoles({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        removeRole(role) {
            api.removeRole(role.id).then((response) => {
                if (204 === response.status) {
                    this.removeModal.reset()
                    this.load()
                }
            })
        },
        removeItem(id) {
            this.removeModal.title = 'Remove role'
            this.removeModal.text = 'Do you want remove this role?'
            this.removeModal.show = true
            this.removeModal.id = id
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('roleSearch', JSON.stringify(this.searchObject))
                this.loadRoles({
                    orderBy: this.orderBy,
                    perPage: this.perPage,
                    search: this.searchObject.text,
                    currentPage: this.currentPage,
                })
            }
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('roleOrderBy', JSON.stringify(this.orderBy))
            this.loadRoles({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
    },
    created() {
        this.load()
    },
    beforeRouteUpdate(to, from, next) {
        if (from.name === to.name) {
            this.load()
        }
        next()
    },
}
</script>
