import { defineStore } from 'pinia'
import api from '@/api'

export const useLandingsStore = defineStore('landings', {
    state: () => ({
        landing: [],
        landings: [],
    }),
    getters: {
        getLanding: ({ landing }) => landing,
        getLandings: ({ landings }) => landings,
        getPagination: ({ landings }) => {
            const { ...paginationData } = landings
            return paginationData
        },
    },
    actions: {
        async loadLanding(id) {
            const response = await api.landing(id)
            if (response.status === 200) {
                this.landing = response.data.data.landing
            }
        },
        async loadLandings(params) {
            const response = await api.landingList(params)
            if (response.status === 200) {
                this.landings = response.data.data.landings
            }
        },
        async loadPartnerLandingList(params) {
            const response = await api.loadPartnerLandings(params)
            if (response.status === 200) {
                this.landings = response.data.data.landings
            }
        },
        async removeLanding(id) {
            const response = await api.removeLanding(id)
            if (response.status === 204) {
                const index = this.landings.items.findIndex((landing) => landing.id === id)
                if (index !== -1) {
                    this.landings.items.splice(index, 1)
                }
            }
        },
    },
})
