<template>
    <div class="container">
        <div class="error-500">
            <h1><span title="Помилка на сервері. Можливо неправильний запит">500</span> |</h1>
            <router-link :to="{ name: 'ClientList' }">На головну</router-link>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */

export default {
    name: 'Error500',
}
</script>

<style scoped>
.container {
    background-color: #f6f6f6;
    font-family: Roboto, serif !important;
    width: 500px;
    margin: 200px auto;
}

.error-500 {
    display: flex;
    padding: 5px;
}

h1 {
    font-size: 36px;
}

p {
    font-size: 18px;
    line-height: 1.5;
}

a {
    text-decoration: none;
    margin: 10px;
    color: #000;
}
</style>
