<template>
    <master-view>
        <remove-modal :modal="removeModal" @cancel="removeModal.reset" @agree="removePermission" />
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>List users</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <!-- id -->
                                    <th class="table-header-title" @click="toggleSort('id')">
                                        id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>
                                    <!-- name -->
                                    <th class="table-header-title" @click="toggleSort('name')">
                                        name
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('name', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('name', 'asc')"
                                        />
                                    </th>
                                    <!-- slug -->
                                    <th class="table-header-title" @click="toggleSort('slug')">
                                        slug
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('email', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('email', 'asc')"
                                        />
                                    </th>
                                    <!-- description -->
                                    <th class="table-header-title col-4">description</th>
                                    <!-- created_at -->
                                    <th class="table-header-title" @click="toggleSort('created_at')">
                                        created_at
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('created_at', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('created_at', 'asc')"
                                        />
                                    </th>
                                    <th>actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(permission, index) in getPermissions" :key="permission.id">
                                    <th scope="row">{{ getAmountNumber(index) }}</th>
                                    <td>{{ permission.id }}</td>
                                    <td>{{ permission.name }}</td>
                                    <td>{{ permission.slug }}</td>
                                    <td>{{ getDescription(permission) }}</td>
                                    <td>{{ formatDate(permission.created_at) }}</td>
                                    <td>
                                        <router-link :to="{ name: 'PermissionEdit', params: { id: permission.id } }">
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon" />
                                        </router-link>
                                        <font-awesome-icon
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon"
                                            @click="removeItem(permission.id)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import RemoveModal from '@/components/Modals/RemoveModal.vue'
import { mapActions, mapState } from 'pinia'
import { usePermissionsStore } from '@/store/permissions'
import api from '@/api'

export default {
    name: 'PermissionList',
    components: { RemoveModal, MasterView, FooterPanel },
    computed: {
        ...mapState(usePermissionsStore, ['getPermissionsList', 'getPagination']),
        isSearchActive() {
            return this.searchObject.active
        },
        getPermissions() {
            if (this.getPermissionsList) {
                return this.getPermissionsList
            }
            return []
        },
    },
    data() {
        return {
            perPage: 10,
            activeMenuItem: [],
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            removeModal: {
                show: false,
                title: null,
                text: null,
                id: null,
                reset: () => {
                    this.removeModal.title = null
                    this.removeModal.text = null
                    this.removeModal.show = false
                },
            },
            searchObject: {
                text: '',
                active: false,
            },
            type: '',
            currentPage: null,
        }
    },
    methods: {
        ...mapActions(usePermissionsStore, ['loadPermissions']),
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('permissionSearch')
            this.loadPermissions({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        getDescription(permission) {
            return permission.description ?? '-'
        },
        formatDate(dateString) {
            const date = new Date(dateString)
            return date.toLocaleString()
        },
        load() {
            const perPage = localStorage.getItem('permissionPerPage')
            const orderBy = localStorage.getItem('permissionOrderBy')
            const searchLanding = JSON.parse(localStorage.getItem('permissionSearch'))
            const currentPage = localStorage.getItem('permissionCurrentPage')
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchLanding && searchLanding.active) {
                this.searchObject.active = searchLanding.active
                this.searchObject.text = searchLanding.text
            }
            if (currentPage) {
                this.currentPage = currentPage
            }
            this.loadPermissions({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        onChangePerPage() {
            localStorage.setItem('permissionPerPage', this.perPage)
            this.loadPermissions({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
        removeItem(id) {
            this.removeModal.title = 'Remove permission'
            this.removeModal.text = 'Do you want remove this Item ?'
            this.removeModal.show = true
            this.removeModal.id = id
        },
        removePermission(user) {
            api.removePermission(user.id).then((response) => {
                if (204 === response.status) {
                    this.removeModal.reset()
                    this.load()
                }
            })
        },
        search() {
            if (this.searchObject.text) {
                this.searchObject.active = true
                localStorage.setItem('permissionSearch', JSON.stringify(this.searchObject))
                this.loadPermissions({
                    orderBy: this.orderBy,
                    perPage: this.perPage,
                    search: this.searchObject.text,
                    currentPage: this.currentPage,
                })
            }
        },
        searchToggle() {
            if (this.searchObject.active && !this.searchObject.text) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('permissionOrderBy', JSON.stringify(this.orderBy))
            this.loadPermissions({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: this.currentPage,
            })
        },
    },
    created() {
        this.load()
    },
    beforeRouteUpdate(to, from, next) {
        if (from.name === to.name) {
            this.load()
        }
        next()
    },
}
</script>
