<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Create user</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="section">
                                <div class="section-title">General</div>
                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        User name <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="name" v-model="user.name" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        User email <span class="required">*</span>
                                    </label>
                                    <input type="text" class="form-control" id="email" v-model="user.email" />
                                </div>
                            </div>

                            <div class="section">
                                <div class="section-title">
                                    Roles <small>(!) changes will be saved immediately</small>
                                </div>
                                <div class="d-flex mt-4">
                                    <div class="col-6">
                                        <div class="row pl-3">Selected</div>
                                        <draggable v-model="selectedRoles" group="roles" item-key="id">
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>

                                    <div class="col-6">
                                        <div class="row pl-3">Unselected</div>
                                        <draggable v-model="unselectedRoles" group="roles" item-key="id">
                                            <template #item="{ element }">
                                                <div class="role-list-item">{{ element.name }}</div>
                                            </template>
                                        </draggable>
                                    </div>
                                </div>
                            </div>

                            <div class="section">
                                <div class="section-title">Password</div>
                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        Password <span class="required">*</span>
                                    </label>
                                    <input type="password" class="form-control" id="password" v-model="user.password" />
                                </div>

                                <div class="col-12 mt-4">
                                    <label for="title" class="form-field-title">
                                        Password confirm<span class="required">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="password_confirmation"
                                        v-model="user.password_confirmation"
                                    />
                                </div>
                            </div>

                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button
                                        :disabled="!isAdmin"
                                        type="button"
                                        class="btn btn-primary"
                                        id="upload-btn"
                                        @click="create"
                                    >
                                        Create
                                    </button>
                                    <button type="button" class="btn btn-outline-success" @click="cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import MasterView from '@/views/Admin/MasterView.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/store/messages'
import { useUserStore } from '@/store/user'
import validation from '@/mixins/validation'
import api from '@/api'
import user from '@/mixins/user'
import draggable from 'vuedraggable'

export default {
    name: 'UserCreate',
    components: { draggable, FooterPanel, MessageItem, MasterView },
    data() {
        return {
            pluckRolesArray: [],
            selectedRoles: [],
            unselectedRoles: [],
            user: {
                email: null,
                name: null,
                password: null,
                password_confirmation: null,
            },
            validateRules: {
                email: { message: 'Field email is required' },
                name: { message: 'Field name is required' },
                password: { message: 'Field password is required' },
                password_confirmation: { message: 'Field password_confirmation is required' },
            },
        }
    },
    computed: {
        ...mapState(useMessagesStore, ['getMessages']),
        ...mapState(useUserStore, ['getUser']),
    },
    mixins: [validation, user],
    methods: {
        ...mapActions(useMessagesStore, ['addMessages']),
        ...mapActions(useUserStore, ['loadUser']),
        cancel() {
            this.$router.go(-1)
        },
        create() {
            let validationResult = this.validateForm(this.user, this.validateRules)
            if (validationResult) {
                this.user.roles = this.selectedRoles.map((role) => role.id)
                api.storeUser(this.user)
                    .then((response) => {
                        console.log(response.status)
                        if (response.status === 201) {
                            this.addMessages({
                                title: 'Success',
                                type: 'success',
                                text: 'User create successfully',
                                timer: 3,
                            })
                            setTimeout(() => {
                                this.$router.push({ name: 'UserList' })
                            }, 3000)
                        } else {
                            if (response.response.data.errors) {
                                this.addMessages({
                                    title: 'Error',
                                    type: 'error',
                                    text: response.response.data.errors[0],
                                    timer: 3,
                                })
                            } else {
                                this.addMessages({
                                    title: 'Error',
                                    type: 'error',
                                    text: 'User not created',
                                    timer: 3,
                                })
                            }
                        }
                    })
                    .catch((errors) => {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: errors.response.data.errors[0],
                            timer: 3,
                        })
                    })
            }
        },
        load() {
            api.getCurrentUser().then((userResponse) => {
                api.rolesList({}).then((response) => {
                    this.unselectedRoles = response.data.data.roles.items.filter((role) => {
                        const isNotAssigned = !this.pluckRolesArray.includes(role.slug)

                        const isSuperadminAndUserNotSuperadmin =
                            role.slug === 'superadmin' && userResponse.data.data.user.roles[0].slug !== 'superadmin'

                        return isNotAssigned && !isSuperadminAndUserNotSuperadmin
                    })
                })
            })
        },
    },
    created() {
        this.load()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>
