<template>
    <div class="col-md-3 left_col">
        <div class="left_col scroll-view">
            <div class="navbar nav_title logo-box">
                <img src="/icons/logo.png" class="logo-box-img" alt="" />
            </div>

            <div class="clearfix"></div>

            <br />

            <div class="menu_section mt-5">
                <h3>General</h3>
                <ul class="nav side-menu">
                    <li :class="{ active: isMenuActive('landings') }">
                        <a @click="toggleMenu('landings')" style="padding-right: 15px">
                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('landings') }">
                                <span class="col-1 p-0">
                                    <font-awesome-icon :icon="['fas', 'earth-americas']" style="display: flex" />
                                </span>
                                <span class="col-10"> Landings </span>
                                <span>
                                    <font-awesome-icon
                                        v-if="isMenuActive('landings')"
                                        :icon="['fas', 'chevron-down']"
                                        style="display: flex; justify-content: end"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'chevron-up']"
                                        style="display: flex; justify-content: end"
                                    />
                                </span>
                            </div>
                        </a>

                        <ul class="nav child_menu show" v-if="isMenuActive('landings')">
                            <li :class="{ 'current-page': isMenuItemActive('FastRegistrationLandingList') }">
                                <router-link
                                    :to="{ name: 'FastRegistrationLandingList' }"
                                    @click="toggleMenuItem('FastRegistrationLandingList')"
                                >
                                    Fast registration landing list
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('GeneralLandingLinkList') }">
                                <router-link
                                    :to="{ name: 'GeneralLandingLinkList' }"
                                    @click="toggleMenuItem('GeneralLandingLinkList')"
                                >
                                    General landing list (link)
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('GeneralLandingScriptList') }">
                                <router-link
                                    :to="{ name: 'GeneralLandingScriptList' }"
                                    @click="toggleMenuItem('GeneralLandingScriptList')"
                                >
                                    General landing list (script)
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('PartnerLandingList') }">
                                <router-link
                                    :to="{ name: 'PartnerLandingList' }"
                                    @click="toggleMenuItem('PartnerLandingList')"
                                >
                                    Partner landing list
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li :class="{ active: isMenuActive('domains') }">
                        <a @click="toggleMenu('domains')" style="padding-right: 15px">
                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('domains') }">
                                <span class="col-1 p-0">
                                    <font-awesome-icon :icon="['fas', 'gears']" style="display: flex" />
                                </span>
                                <span class="col-10"> Domains </span>
                                <span>
                                    <font-awesome-icon
                                        v-if="isMenuActive('domains')"
                                        :icon="['fas', 'chevron-down']"
                                        style="display: flex; justify-content: end"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'chevron-up']"
                                        style="display: flex; justify-content: end"
                                    />
                                </span>
                            </div>
                        </a>
                        <ul class="nav child_menu show" v-if="isMenuActive('domains')">
                            <li :class="{ 'current-page': isMenuItemActive('DomainList') }">
                                <router-link :to="{ name: 'DomainList' }" @click="toggleMenuItem('DomainList')">
                                    Domain list
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('DomainCreate') }">
                                <router-link :to="{ name: 'DomainCreate' }" @click="toggleMenuItem('DomainCreate')">
                                    Domain create
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li :class="{ active: isMenuActive('create') }">
                        <a @click="toggleMenu('create')" style="padding-right: 15px">
                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('create') }">
                                <span class="col-1 p-0">
                                    <font-awesome-icon :icon="['fas', 'fa-plus']" style="display: flex" />
                                </span>
                                <span class="col-10"> Create landings </span>
                                <span>
                                    <font-awesome-icon
                                        v-if="isMenuActive('create')"
                                        :icon="['fas', 'chevron-down']"
                                        style="display: flex; justify-content: end"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'chevron-up']"
                                        style="display: flex; justify-content: end"
                                    />
                                </span>
                            </div>
                        </a>

                        <ul class="nav child_menu show" v-if="isMenuActive('create')">
                            <li :class="{ 'current-page': isMenuItemActive('FastRegistrationLanding') }">
                                <router-link
                                    :to="{ name: 'FastRegistrationLanding' }"
                                    @click="toggleMenuItem('FastRegistrationLanding')"
                                >
                                    Fast registration land
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('GeneralLandingLink') }">
                                <router-link
                                    :to="{ name: 'GeneralLandingLink' }"
                                    @click="toggleMenuItem('GeneralLandingLink')"
                                >
                                    General land (link)
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('GeneralLandingScript') }">
                                <router-link
                                    :to="{ name: 'GeneralLandingScript' }"
                                    @click="toggleMenuItem('GeneralLandingScript')"
                                >
                                    General land (script)
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('PartnerLandingCreate') }">
                                <router-link
                                    :to="{ name: 'PartnerLandingCreate' }"
                                    @click="toggleMenuItem('PartnerLandingCreate')"
                                >
                                    Partner landing
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li :class="{ active: isMenuActive('clients') }">
                        <a @click="toggleMenu('clients')" style="padding-right: 15px">
                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('clients') }">
                                <span class="col-1 p-0">
                                    <font-awesome-icon :icon="['fas', 'user-group']" style="display: flex" />
                                </span>
                                <span class="col-10"> Clients </span>
                                <span>
                                    <font-awesome-icon
                                        v-if="isMenuActive('clients')"
                                        :icon="['fas', 'chevron-down']"
                                        style="display: flex; justify-content: end"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'chevron-up']"
                                        style="display: flex; justify-content: end"
                                    />
                                </span>
                            </div>
                        </a>
                        <ul class="nav child_menu show" v-if="isMenuActive('clients')">
                            <li class="current-page">
                                <router-link :to="{ name: 'ClientList' }" @click="toggleMenuItem('ClientList')">
                                    Client list
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li :class="{ active: isMenuActive('partners') }">
                        <a @click="toggleMenu('partners')" style="padding-right: 15px">
                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('partners') }">
                                <span class="col-1 p-0">
                                    <font-awesome-icon :icon="['fas', 'user-group']" style="display: flex" />
                                </span>
                                <span class="col-10"> Partners </span>
                                <span>
                                    <font-awesome-icon
                                        v-if="isMenuActive('partners')"
                                        :icon="['fas', 'chevron-down']"
                                        style="display: flex; justify-content: end"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'chevron-up']"
                                        style="display: flex; justify-content: end"
                                    />
                                </span>
                            </div>
                        </a>
                        <ul class="nav child_menu show" v-if="isMenuActive('partners')">
                            <li class="current-page">
                                <router-link
                                    :to="{ name: 'PartnerClientsList' }"
                                    @click="toggleMenuItem('PartnerClientsList')"
                                >
                                    Partner list
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li :class="{ active: isMenuActive('deleted') }">
                        <a @click="toggleMenu('deleted')" style="padding-right: 15px">
                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('deleted') }">
                                <span class="col-1 p-0">
                                    <font-awesome-icon :icon="['fas', 'trash']" style="display: flex" />
                                </span>
                                <span class="col-10"> Trash </span>
                                <span>
                                    <font-awesome-icon
                                        v-if="isMenuActive('deleted')"
                                        :icon="['fas', 'chevron-down']"
                                        style="display: flex; justify-content: end"
                                    />
                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'chevron-up']"
                                        style="display: flex; justify-content: end"
                                    />
                                </span>
                            </div>
                        </a>
                        <ul class="nav child_menu show" v-if="isMenuActive('deleted')">
                            <li :class="{ 'current-page': isMenuItemActive('FastRegistrationLandingDeletedList') }">
                                <router-link
                                    :to="{ name: 'FastRegistrationLandingDeletedList' }"
                                    @click="toggleMenuItem('FastRegistrationLandingDeletedList')"
                                >
                                    Fast registration landing
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('GeneralLandingLinkDeletedList') }">
                                <router-link
                                    :to="{ name: 'GeneralLandingLinkDeletedList' }"
                                    @click="toggleMenuItem('GeneralLandingLinkDeletedList')"
                                >
                                    General landing (link)
                                </router-link>
                            </li>
                            <li :class="{ 'current-page': isMenuItemActive('GeneralLandingScriptDeletedList') }">
                                <router-link
                                    :to="{ name: 'GeneralLandingScriptDeletedList' }"
                                    @click="toggleMenuItem('GeneralLandingScriptDeletedList')"
                                >
                                    General landing (script)
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <!--            <div class="menu_section mt-5" v-if="isAdmin">-->
            <!--                <h3>Admin</h3>-->
            <!--                <ul class="nav side-menu">-->
            <!--                    <li :class="{ active: isMenuActive('users') }">-->
            <!--                        <a @click="toggleMenu('users')" style="padding-right: 15px">-->
            <!--                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('users') }">-->
            <!--                                <span class="col-1 p-0">-->
            <!--                                    <font-awesome-icon :icon="['fas', 'users']" style="display: flex" />-->
            <!--                                </span>-->
            <!--                                <span class="col-10"> Users </span>-->
            <!--                                <span>-->
            <!--                                    <font-awesome-icon-->
            <!--                                        v-if="isMenuActive('users')"-->
            <!--                                        :icon="['fas', 'chevron-down']"-->
            <!--                                        style="display: flex; justify-content: end"-->
            <!--                                    />-->
            <!--                                    <font-awesome-icon-->
            <!--                                        v-else-->
            <!--                                        :icon="['fas', 'chevron-up']"-->
            <!--                                        style="display: flex; justify-content: end"-->
            <!--                                    />-->
            <!--                                </span>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                        <ul class="nav child_menu show" v-if="isMenuActive('users')">-->
            <!--                            <li class="current-page">-->
            <!--                                <router-link :to="{ name: 'UserList' }" @click="toggleMenuItem('UserList')">-->
            <!--                                    User list-->
            <!--                                </router-link>-->
            <!--                            </li>-->
            <!--                            <li class="current-page">-->
            <!--                                <router-link :to="{ name: 'UserCreate' }" @click="toggleMenuItem('UserCreate')">-->
            <!--                                    User create-->
            <!--                                </router-link>-->
            <!--                            </li>-->
            <!--                        </ul>-->
            <!--                    </li>-->

            <!--                    <li :class="{ active: isMenuActive('roles') }">-->
            <!--                        <a @click="toggleMenu('roles')" style="padding-right: 15px">-->
            <!--                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('roles') }">-->
            <!--                                <span class="col-1 p-0">-->
            <!--                                    <font-awesome-icon :icon="['fas', 'user-group']" style="display: flex" />-->
            <!--                                </span>-->
            <!--                                <span class="col-10"> Roles </span>-->
            <!--                                <span>-->
            <!--                                    <font-awesome-icon-->
            <!--                                        v-if="isMenuActive('roles')"-->
            <!--                                        :icon="['fas', 'chevron-down']"-->
            <!--                                        style="display: flex; justify-content: end"-->
            <!--                                    />-->
            <!--                                    <font-awesome-icon-->
            <!--                                        v-else-->
            <!--                                        :icon="['fas', 'chevron-up']"-->
            <!--                                        style="display: flex; justify-content: end"-->
            <!--                                    />-->
            <!--                                </span>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                        <ul class="nav child_menu show" v-if="isMenuActive('roles')">-->
            <!--                            <li class="current-page">-->
            <!--                                <router-link :to="{ name: 'RoleList' }" @click="toggleMenuItem('RoleList')">-->
            <!--                                    Role list-->
            <!--                                </router-link>-->
            <!--                            </li>-->
            <!--                            <li class="current-page">-->
            <!--                                <router-link :to="{ name: 'RoleCreate' }" @click="toggleMenuItem('RoleCreate')">-->
            <!--                                    Role create-->
            <!--                                </router-link>-->
            <!--                            </li>-->
            <!--                        </ul>-->
            <!--                    </li>-->

            <!--                    <li :class="{ active: isMenuActive('permissions') }">-->
            <!--                        <a @click="toggleMenu('permissions')" style="padding-right: 15px">-->
            <!--                            <div class="menu-item" :class="{ 'active-margin-right': !isMenuActive('permissions') }">-->
            <!--                                <span class="col-1 p-0">-->
            <!--                                    <font-awesome-icon :icon="['fas', 'user-lock']" style="display: flex" />-->
            <!--                                </span>-->
            <!--                                <span class="col-10"> Permissions </span>-->
            <!--                                <span>-->
            <!--                                    <font-awesome-icon-->
            <!--                                        v-if="isMenuActive('permissions')"-->
            <!--                                        :icon="['fas', 'chevron-down']"-->
            <!--                                        style="display: flex; justify-content: end"-->
            <!--                                    />-->
            <!--                                    <font-awesome-icon-->
            <!--                                        v-else-->
            <!--                                        :icon="['fas', 'chevron-up']"-->
            <!--                                        style="display: flex; justify-content: end"-->
            <!--                                    />-->
            <!--                                </span>-->
            <!--                            </div>-->
            <!--                        </a>-->
            <!--                        <ul class="nav child_menu show" v-if="isMenuActive('permissions')">-->
            <!--                            <li class="current-page">-->
            <!--                                <router-link :to="{ name: 'PermissionList' }" @click="toggleMenuItem('PermissionList')">-->
            <!--                                    Permission list-->
            <!--                                </router-link>-->
            <!--                            </li>-->
            <!--                            <li class="current-page">-->
            <!--                                <router-link-->
            <!--                                    :to="{ name: 'PermissionCreate' }"-->
            <!--                                    @click="toggleMenuItem('PermissionCreate')"-->
            <!--                                >-->
            <!--                                    Permission create-->
            <!--                                </router-link>-->
            <!--                            </li>-->
            <!--                        </ul>-->
            <!--                    </li>-->
            <!--                </ul>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/store/user'
import user from '@/mixins/user'

export default {
    name: 'SidebarPanel',
    data() {
        return {
            activeMenu: [],
            activeMenuItem: [],
            menuBuMenuItem: {
                ClientList: 'clients',
                DomainList: 'domains',
                DomainCreate: 'domains',
                FastRegistrationLanding: 'create',
                GeneralLandingLink: 'create',
                GeneralLandingScript: 'create',
                PartnerLandingCreate: 'create',
                FastRegistrationLandingList: 'landings',
                GeneralLandingLinkList: 'landings',
                GeneralLandingScriptList: 'landings',
                PartnerLandingList: 'landings',
                UserCreate: 'users',
                UserList: 'users',
                UserPermissions: 'users',
                RoleCreate: 'roles',
                RoleList: 'roles',
                PermissionCreate: 'permissions',
                PermissionList: 'permissions',
                FastRegistrationLandingDeletedList: 'deleted',
                GeneralLandingLinkDeletedList: 'deleted',
                GeneralLandingScriptDeletedList: 'deleted',
                PartnerClientsList: 'partners',
            },
        }
    },
    computed: {
        ...mapState(useUserStore, ['getUser']),
    },
    mixins: [user],
    methods: {
        isMenuActive(menu) {
            return this.activeMenu.includes(menu)
        },
        isMenuItemActive(item) {
            return this.activeMenuItem.includes(item)
        },
        loadCurrentRouteFromLocalStorage() {
            if (localStorage.getItem('currentRoute') === this.$route.name) {
                this.activeMenuItem = []
                this.activeMenuItem.push(this.$route.name)
                this.activeMenu.push(this.menuBuMenuItem[this.$route.name])
            }
        },
        toggleMenu(menu) {
            if (this.activeMenu.includes(menu)) {
                this.activeMenu = []
            } else {
                this.activeMenu = []
                this.activeMenu.push(menu)
            }
        },
        toggleMenuItem(item) {
            console.log('true')
            this.activeMenuItem = []
            if (!this.activeMenuItem.includes(item)) {
                this.activeMenuItem.push(item)
            }
        },
    },
    created() {
        this.loadCurrentRouteFromLocalStorage()
    },
}
</script>

<style scoped>
.disabled {
    color: #7a7a7a !important;
}
</style>
