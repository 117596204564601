<template>
    <master-view>
        <div class="right_col" role="main">
            <div class="messages">
                <message-item />
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Create domain</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content d-flex">
                        <div class="col-6">
                            <div class="col-11 mt-4">
                                <label class="form-field-title">Domain url <span class="required">*</span></label>
                                <div v-for="(url, index) in domain.urls" :key="index" class="d-flex mt-3">
                                    <input type="text" class="form-control" v-model="url.url" />
                                    <font-awesome-icon
                                        :icon="['far', 'square-plus']"
                                        class="action-add"
                                        @click="addUrl"
                                    />
                                    <font-awesome-icon
                                        :icon="['far', 'trash-can']"
                                        class="action-add"
                                        @click="removeUrl(index)"
                                    />
                                </div>
                            </div>

                            <div class="col-12 mt-4">
                                <label class="mt-2" for="country-id">Country <span class="required">*</span></label>
                                <select id="country-id" class="form-control" v-model="domain.country_id">
                                    <option v-for="country in getCountries" :key="country.id" :value="country.id">
                                        {{ country.title }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 mt-4">
                                <label class="mt-2" for="country-id">Currency <span class="required">*</span></label>
                                <select id="country-id" class="form-control" v-model="domain.currency_id">
                                    <option v-for="currency in getCurrencies" :key="currency.id" :value="currency.id">
                                        {{ currency.code }}
                                    </option>
                                </select>
                            </div>

                            <hr />
                            <div class="col p-0">
                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" id="upload-btn" @click="create">
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useCountriesStore } from '@/store/countries'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MessageItem from '@/components/Messages/MessageItem.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import { useCurrenciesStore } from '@/store/currencies'
import api from '@/api'
import { useMessagesStore } from '@/store/messages'
import validation from '@/mixins/validation'

export default {
    name: 'DomainCreate',
    components: { MasterView, MessageItem, FooterPanel },
    data() {
        return {
            domain: {
                urls: [{ order: 1, url: null }],
                country_id: null,
                currency_id: null,
            },
            validateRules: {
                country_id: { message: 'Field country is required' },
                currency_id: { message: 'Field currency must be at least 3 characters long' },
            },
        }
    },
    computed: {
        ...mapState(useCountriesStore, ['getCountries']),
        ...mapState(useCurrenciesStore, ['getCurrencies']),
        ...mapState(useMessagesStore, ['getMessages']),
    },
    mixins: [validation],
    methods: {
        ...mapActions(useCountriesStore, ['loadCountries']),
        ...mapActions(useCurrenciesStore, ['loadCurrencies']),
        ...mapActions(useMessagesStore, ['addMessages']),
        addUrl() {
            let amount = this.domain.urls.length
            this.domain.urls.push({ order: amount + 1, url: '' })
        },
        agree() {
            this.$emit('agree', {
                country_id: this.country_id,
                domains: this.domain_link,
            })
            this.domain_link = null
            this.country_id = null
        },
        create() {
            let validationResult = this.validateForm(this.domain, this.validateRules)
            if (this.domain.urls[0].url === null) {
                this.addMessages({
                    title: 'Error',
                    type: 'error',
                    text: 'Domain url is empty',
                    timer: 3,
                })
                validationResult = false
            }

            if (validationResult) {
                let data = { country_id: this.domain.country_id, currency_id: this.domain.currency_id, urls: [] }
                this.domain.urls.forEach((item) => {
                    data.urls.push(item.url)
                })

                api.storeDomain(data).then((response) => {
                    if (response.status === 201) {
                        this.addMessages({
                            title: 'Success',
                            type: 'success',
                            text: 'Domain create successfully',
                            timer: 3,
                        })
                    } else {
                        this.addMessages({
                            title: 'Error',
                            type: 'error',
                            text: 'Domain not created',
                            timer: 3,
                        })
                    }
                })
            }
        },
        handleKeydown(evt) {
            if (evt.key === 'Escape') {
                this.cancel()
            } else if (evt.key === 'Enter') {
                this.agree()
            }
        },
        removeUrl(index) {
            this.domain.urls.splice(index, 1)
        },
    },
    created() {
        this.loadCountries()
        this.loadCurrencies({})
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown)
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown)
    },
}
</script>

<style scoped></style>
