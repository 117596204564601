<template>
    <nav v-if="isShowComponent" class="d-flex justify-content-center">
        <ul class="pagination justify-content-center">
            <li class="page-item" v-if="isShowStartArrow" @click.prevent="changePage(prevPage)">
                <a class="page-link" href="#" tabindex="-1">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </a>
            </li>

            <li class="page-item" v-for="(page, index) in pages" :key="index">
                <a
                    class="page-link"
                    href="#"
                    @click.prevent="changePage(page)"
                    :class="{ current: isCurrentPage(page) }"
                >
                    {{ page }}
                </a>
            </li>

            <li class="page-item" v-if="isShowEndArrow" @click.prevent="changePage(nextPage)">
                <a class="page-link" href="#">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </a>
            </li>
            <input type="text" class="form-control page" v-model.number="hopTo" @keydown.enter="changePage(hopTo)" />
        </ul>
        <div class="pagination-pages">{{ pagination.currentPage }}/{{ pagination.lastPage }}</div>
    </nav>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'PaginationPanel',
    data() {
        return {
            hopTo: null,
            paginationRange: 3,
        }
    },
    components: {
        FontAwesomeIcon,
    },
    computed: {
        isShowComponent() {
            return this.pagination.lastPage > 1
        },
        isShowStartArrow() {
            return this.pagination.currentPage > this.pageRange
        },
        isShowEndArrow() {
            if (this.pagination.lastPage > this.pageRange) {
                return this.pagination.currentPage < this.pagination.total - this.pageRange
            }
            return false
        },
        nextPage() {
            return this.pagination.currentPage + 1
        },
        pages() {
            let pages = []

            for (let i = 1; i <= this.pagination.lastPage; i++) {
                if (
                    i >= this.pagination.currentPage - this.paginationRange &&
                    i <= this.pagination.currentPage + this.paginationRange
                ) {
                    pages.push(i)
                }
            }

            return pages
        },
        prevPage() {
            return this.pagination.currentPage - 1
        },
    },
    props: {
        pagination: {
            type: Object,
            default: () => ({}),
        },
        pageRange: {
            type: Number,
            default: 3,
        },
    },
    methods: {
        changePage(page) {
            this.hopTo = null
            if (page > this.pagination.lastPage) {
                page = this.pagination.lastPage
            }
            this.$emit('pageChanged', page)
        },
        isCurrentPage(page) {
            return this.pagination.currentPage === page
        },
    },
}
</script>

<style scoped>
.current,
.current:hover {
    color: #fff !important;
    background-color: #3f5367 !important;
    border: 1px solid #3f5367 !important;
}
</style>
