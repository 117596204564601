import { defineStore } from 'pinia'
import api from '@/api'

export const useCurrenciesStore = defineStore('currencies', {
    state: () => ({
        currencies: [],
    }),
    getters: {
        getCurrencies: ({ currencies }) => currencies.items,
    },
    actions: {
        async loadCurrencies(params) {
            const response = await api.currenciesList(params)
            if (response.status === 200) {
                this.currencies = response.data.data.currencies
            }
        },
    },
})
