<template>
    <master-view>
        <remove-modal :modal="removeModal" @cancel="removeModal.reset" @agree="removeDomainItem" />
        <div class="right_col" role="main">
            <div class="col-md-12 col-sm-12">
                <div class="x_panel">
                    <div class="x_title">
                        <h2>Domains</h2>
                        <div class="clearfix"></div>
                    </div>
                    <div class="x_content">
                        <div class="item form-group">
                            <div class="col-md-6 p-0">
                                <div class="col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        class="form-control search-input"
                                        v-model="searchObject.text"
                                        @keydown.enter="searchToggle"
                                    />
                                </div>
                                <button
                                    v-if="!isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="search"
                                >
                                    <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                </button>
                                <button
                                    v-if="isSearchActive"
                                    class="btn btn-primary search-button"
                                    type="button"
                                    @click="clearSearch"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end">
                                <div class="col-md-2">
                                    <div class="dataTables_length">
                                        <select
                                            class="form-control input-sm"
                                            v-model="perPage"
                                            @change="onChangePerPage"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>

                                    <!-- id -->
                                    <th class="table-header-title col-1" @click="toggleSort('id')">
                                        Id
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('id', 'asc')"
                                        />
                                    </th>

                                    <!-- domains -->
                                    <th class="table-header-title search-column" @click="toggleSort('domains')">
                                        Domains
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('domains', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('domains', 'asc')"
                                        />
                                    </th>

                                    <!-- current domain -->
                                    <th class="table-header-title" @click="toggleSort('current_domain')">
                                        Current domain
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('current_domain', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('current_domain', 'asc')"
                                        />
                                    </th>

                                    <!-- country -->
                                    <th class="table-header-title col-1" @click="toggleSort('country_id')">
                                        Country
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-down-long']"
                                            v-if="isCurrentSort('country_id', 'desc')"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-up-long']"
                                            v-if="isCurrentSort('country_id', 'asc')"
                                        />
                                    </th>

                                    <!-- current currency -->
                                    <th>Current currency</th>

                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(domain, index) in getDomains.items" :key="domain.id">
                                    <th scope="row">{{ getAmountNumber(index) }}</th>

                                    <td>{{ domain['id'] }}</td>

                                    <td>
                                        <div class="row" v-for="(item, index) in domain['domains']" :key="index">
                                            <a :href="item">{{ item ?? '' }}</a>
                                        </div>
                                    </td>

                                    <td>
                                        <select
                                            class="form-control input-sm"
                                            v-model="domain['current_domain']"
                                            @change="changeCurrentDomain(domain)"
                                        >
                                            <option
                                                v-for="url in domain.domains"
                                                :key="url"
                                                :value="url"
                                                :selected="url && url === domain['current_domain']"
                                            >
                                                {{ url }}
                                            </option>
                                        </select>
                                    </td>

                                    <td>{{ getCountryTitle(domain) }}</td>

                                    <td>
                                        <select
                                            class="form-control input-sm"
                                            v-model="domain['currency']['id']"
                                            @change="changeCurrentCurrency(domain['currency']['id'], domain['id'])"
                                        >
                                            <option
                                                v-for="currency in getCurrencies"
                                                :key="currency.id"
                                                :value="currency.id"
                                                :selected="isCurrencySelected(currency, domain)"
                                            >
                                                {{ currency.code }}
                                            </option>
                                        </select>
                                    </td>

                                    <td class="col-1">
                                        <router-link :to="{ name: 'DomainEdit', params: { id: domain.id } }">
                                            <font-awesome-icon :icon="['fas', 'pencil']" class="action-icon" />
                                        </router-link>
                                        <font-awesome-icon
                                            :icon="['fas', 'trash-can']"
                                            class="action-icon"
                                            @click="removeItem(domain.id)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination :pagination="getPagination" @pageChanged="changePage" />
            </div>
        </div>
        <footer-panel />
    </master-view>
</template>

<script>
import api from '@/api'
import FooterPanel from '@/components/Layouts/FooterPanel.vue'
import MasterView from '@/views/Admin/MasterView.vue'
import Pagination from '@/components/Layouts/PaginationPanel.vue'
import { mapActions, mapState } from 'pinia'
import { useClientsStore } from '@/store/clients'
import { useCountriesStore } from '@/store/countries'
import { useDomainsStore } from '@/store/domains'
import RemoveModal from '@/components/Modals/RemoveModal.vue'
import { useCurrenciesStore } from '@/store/currencies'

export default {
    name: 'DomainList',
    components: { RemoveModal, Pagination, MasterView, FooterPanel },
    data() {
        return {
            perPage: 10,
            activeMenuItem: [],
            removeModal: {
                show: false,
                title: null,
                text: null,
                id: null,
                reset: () => {
                    this.removeModal.title = null
                    this.removeModal.text = null
                    this.removeModal.show = false
                },
            },
            orderBy: {
                column: 'id',
                direction: 'desc',
            },
            searchObject: {
                text: '',
                active: false,
            },
        }
    },
    computed: {
        ...mapState(useClientsStore, ['getClients']),
        ...mapState(useCurrenciesStore, ['getCurrencies']),
        ...mapState(useCountriesStore, ['getCountries']),
        ...mapState(useDomainsStore, ['getDomains', 'getPagination']),
        isSearchActive() {
            return this.searchObject.active
        },
    },
    methods: {
        ...mapActions(useCountriesStore, ['loadCountries']),
        ...mapActions(useCurrenciesStore, ['loadCurrencies']),
        ...mapActions(useDomainsStore, ['loadDomains', 'removeDomain']),
        changeCurrentDomain(domain) {
            api.currentDomainToggle({ domain_id: domain.id, current_domain: domain.current_domain })
        },
        changePage(currentPage) {
            this.loadDomains({
                orderBy: this.orderBy,
                perPage: this.perPage,
                search: this.searchObject.text,
                currentPage: currentPage,
            })
        },
        changeCurrentCurrency(currency_id, domain_id) {
            api.changeCurrentCurrency({ currency_id: currency_id, id: domain_id })
        },
        clearSearch() {
            this.searchObject.text = ''
            this.searchObject.active = false
            localStorage.removeItem('domainSearch')
            this.loadDomains({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        getAmountNumber(index) {
            const pagination = this.getPagination
            if (pagination.currentPage > 1) {
                const currentPage = pagination.currentPage - 1
                return currentPage * pagination.perPage + index + 1
            }
            return index + 1
        },
        getCountryTitle(domain) {
            if (this.getCountries) {
                return this.getCountries.find((item) => item.id === domain.country_id).title
            }
            return '-'
        },
        isCurrentSort(column, direction) {
            return column === this.orderBy.column && direction === this.orderBy.direction
        },
        isCurrencySelected(currency, domain) {
            return currency.id === domain.currency.id
        },
        load() {
            this.loadCountries()
            this.loadCurrencies({})

            const perPage = localStorage.getItem('domainPerPage')
            const orderBy = localStorage.getItem('domainOrderBy')
            const searchDomainActive = JSON.parse(localStorage.getItem('domainSearch'))
            if (perPage) {
                this.perPage = perPage
            }
            if (orderBy) {
                this.orderBy = JSON.parse(orderBy)
            }
            if (searchDomainActive && searchDomainActive.active) {
                this.searchObject.active = searchDomainActive.active
                this.searchObject.text = searchDomainActive.text
            }
            this.loadDomains({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        onChangePerPage() {
            localStorage.setItem('domainPerPage', this.perPage)
            this.loadDomains({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        removeItem(id) {
            this.removeModal.title = 'Remove domain'
            this.removeModal.text = 'Do you want remove this Item ?'
            this.removeModal.show = true
            this.removeModal.id = id
        },
        search() {
            this.searchObject.active = true
            localStorage.setItem('domainSearch', JSON.stringify(this.searchObject))
            this.loadDomains({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
        searchToggle() {
            if (this.searchObject.active) {
                this.clearSearch()
            } else {
                this.search()
            }
        },
        removeDomainItem(domain) {
            api.removeDomain(domain.id).then((response) => {
                if (204 === response.status) {
                    this.removeModal.reset()
                    this.load()
                }
            })
        },
        toggleSort(column) {
            if (column === this.orderBy.column) {
                if (this.orderBy.direction === 'desc') {
                    this.orderBy.direction = 'asc'
                } else {
                    this.orderBy.direction = 'desc'
                }
            } else {
                this.orderBy = {
                    column: column,
                    direction: 'desc',
                }
            }
            localStorage.setItem('domainOrderBy', JSON.stringify(this.orderBy))
            this.loadDomains({ orderBy: this.orderBy, perPage: this.perPage, search: this.searchObject.text })
        },
    },
    created() {
        this.load()
    },
}
</script>
