<template>
    <div class="container">
        <span class="message">Application up</span>
    </div>
</template>

<script>
export default {
    name: 'UpView',
}
</script>
